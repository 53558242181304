/* eslint-disable @typescript-eslint/naming-convention */
const pages = {
  admin: {
    admins: {
      editAdmin: "Edit {name}",
      tableHeadings: {
        actions: "Actions",
        name: "Name"
      }
    },
    article: {
      addTrigger: "Add trigger",
      addTriggersLabel: "Add trigger",
      blurbLabel: "Blurb",
      caregiverEmailLabel: "Caregiver email",
      caregiverFirstNameLabel: "Caregiver first name",
      caregiverLastNameLabel: "Caregiver last name",
      contentLabel: "Content",
      countryCodesLabel: "Countries",
      edit: "Edit",
      editArticle: "Edit article",
      editArticleTitle: "Edit {title}",
      focusAreaLabel: "Focus area(s)",
      hiddenDescription: "This article is hidden from the library, searches, and recommendations, but it can be shared",
      preview: "Preview",
      quoteLabel: "Block quote",
      reallyDelete: "Are you sure you want to delete this trigger?",
      seperator: " / ",
      sourcesLabel: "Sources",
      tagsLabel: "Tags",
      titleLabel: "Title",
      transcriptLabel: "Transcript",
      triggerLabel: "Trigger(s)",
      useCases: "Use case(s)",
      webinarCompletedLabel: "Completed",
      webinarDurationLabel: "Duration",
      webinarOccurrences: "Recurring events",
      webinarStartTimeLabel: "Start time",
      webinarZoomRegistrationUrlLabel: "Zoom registration URL (use this for prospects)"
    },
    articles: {
      content: "Content",
      download: "Export CSV",
      global: "All countries",
      occurrences: "{count, plural, one {# event} other {# events}}",
      tableHeadings: {
        actions: "Actions",
        author: "Author",
        content_type: "Type",
        country_codes: "Countries",
        created_at: "Date added",
        focus_area: "Focus area(s)",
        hidden: "Hidden",
        recurring: "Recurring",
        tags: "Tags",
        title: "Title",
        updated_at: "Last updated",
        webinar_start_time: "Next start time",
        webinar_status: "Status"
      },
      triggers: "Triggers",
      zoomWarning:
        "Note: Live Events must be managed entirely from within Grayce. Modifying, creating, or deleting them from within Zoom will lead to unexpected results."
    },
    automations: {
      actionsExplanation: "These are the things that will happen when the automation is triggered.",
      actionsLabel: "Actions",
      addAction: "Add action",
      addCondition: "Add condition",
      addConditionGroup: "Add condition group",
      application: {
        name: "{owner} - {careProfileName} - {timestamp}"
      },
      automations: "Automations",
      conditionGroupsLabel: "Conditions",
      conditions: "Conditions",
      createAutomation: "Create automation",
      delete: "Are you sure you want to delete this automation?",
      deleteAction: "Delete action",
      deleteCondition: "Delete condition",
      deleteConditionGroup: "Delete group",
      editAutomationTitle: "Edit {title}",
      editTitle: "Edit title",
      newAutomation: "New automation",
      reallyDeleteAction: "Are you sure you want to delete this action?",
      reallyDeleteAutomationCondition: "Are you sure you want to delete this condition?",
      reallyDeleteAutomationConditionGroup:
        "Are you sure you want to delete this group? Doing so will delete all of the conditions within the group.",
      reallyDeleteTrigger: "Are you sure you want to delete this condition?",
      tableHeadings: {
        actions: "Actions",
        author: "Author",
        created_at: "Date added",
        live: "Live",
        runCount: "Run count",
        tags: "Tags",
        title: "Title",
        updated_at: "Last updated"
      },
      titleLabel: "Title",
      triggersExplanation:
        "When all of these things are true, the automation will be triggered, executing all of its actions."
    },
    carePlanTaskTemplates: {
      carePlanTaskTemplates: "Task templates",
      newCarePlanTaskTemplate: "New template"
    },
    client: {
      showTimeLogs: "Show time logs"
    },
    clientTimeLogs: {
      tableHeadings: {
        actions: "Actions",
        activity: "Activity",
        actor: "Actor",
        balance: "Balance",
        date: "Date",
        notes: "Notes",
        support: "Support",
        time: "Time"
      }
    },
    clients: {
      segments: {
        ALL: "All",
        INELIGIBLE: "Not eligible",
        IN_REVIEW: "Eligibility review",
        NO_EXPERT: "No Care Partner",
        UNVERIFIED: "Email not verified",
        count: "({count})"
      }
    },
    community: {
      groups: {
        tableHeadings: {
          discipleKey: "Disciple key",
          id: "id",
          membersCount: "Members count",
          membershipType: "Membership type",
          name: "Name",
          postingPermission: "Posting permission",
          posts: "Posts count"
        }
      },
      posts: {
        tableHeadings: {
          author: "Author",
          childPosts: "Child posts count",
          discipleId: "Disciple id",
          discipleImages: "Disciple images",
          discipleParentPostId: "Disciple parent post id",
          discipleVideos: "Disciple videos",
          gifs: "Gifs",
          group: "Group",
          id: "Post id",
          mediaFiles: "Media files count",
          parentPost: "Parent post id",
          publishedAt: "Published at",
          reactions: "Reactions count",
          text: "Text"
        }
      },
      reactions: {
        tableHeadings: {
          emoji: "Emoji",
          id: "Reaction id",
          postId: "Post id",
          userId: "User id"
        }
      },
      users: {
        tableHeadings: {
          ageRange: "Age range",
          country: "Country",
          discipleDeletedEventSource: "Disciple deleted event source",
          discipleEmail: "Disciple email",
          discipleId: "Disciple id",
          discipleOnboardingCompleted: "Disciple onboarding completed",
          discipleRank: "Disciple rank",
          discipleScore: "Disciple score",
          discipleUsedInviteLink: "Disciple used invite link",
          displayName: "Display name",
          employerName: "Employer name",
          groupMemberships: "Group memberships",
          lastActiveAt: "Last active at",
          lastPlatform: "Last platform",
          posts: "Posts count",
          reactions: "Reactions count",
          relationship: "Relationship",
          timezoneString: "Timezone string"
        }
      }
    },
    contentConditions: {
      nArticles: "{count, plural, one {# article} other {# articles}}",
      tableHeadings: {
        answer: "Answer",
        contentLinked: "Content linked",
        criteria: "Criteria",
        source: "Source"
      }
    },
    demo: {
      storeDatabase: "Save the current data as the standard",
      storeDatabaseExplanation:
        "When you have made changes in this app that you would like to have become the new standard for the demo database, click this button:",
      storeDatabaseStatus: {
        FAILED: "That didn't work. Please let the dev team know that it failed.",
        RUNNING: "Storing the current data...",
        SUCCESS:
          "The backup was successfully created. The data in this demo app is now the standard that new versions will receive."
      }
    },
    eligibility: {
      packageCount: "{count, plural, one {# package} other {# packages}}"
    },
    eligibilityFiles: {
      tableHeadings: {
        active: "Active",
        createdAt: "Created at",
        deletedCount: "Deleted",
        errorCount: "Errors",
        executedAt: "Applied at",
        filename: "Filename",
        incompleteCount: "Incomplete",
        newCount: "New",
        selected: "Selected",
        totalCount: "Total",
        updatedCount: "Updated"
      }
    },
    emailMessage: {
      bouncedAt: "Bounced at",
      complainedAt: "Complained at",
      deliveredAt: "Delivered at",
      openedAt: "Opened at",
      sentAt: "Sent at",
      subject: "Subject",
      to: "To"
    },
    emailMessages: {
      scheduledForFuture: "This email is scheduled to be sent in the future",
      tableHeadings: {
        id: "Id",
        kind: "Type",
        sentAt: "Sent at",
        subject: "Subject",
        to: "To"
      }
    },
    employees: {
      deletedEmployees: "Deleted employees: {count}",
      eligibilityListUpload: "Eligibility list upload:",
      employees: "Employees:",
      errors: "Errors: {count}",
      incompleteEmployees: "Incomplete employees: {count}",
      journey: "Journey",
      latestEligibilityFile: "Latest eligibility file:",
      newEmployees: "New employees: {count}",
      notLinked: "Not linked yet",
      previousEligibilityFiles: "Previous eligibility files:",
      scanningEligibilityFile: "Scanning eligibility file",
      tableHeadings: {
        country: "Country",
        date_of_birth: "Date of birth",
        email: "Email",
        employee_id: "Id",
        member: "Member",
        name: "Name"
      },
      totalEmployees: "Total employees: {count}",
      updatedEmployees: "Updated employees: {count}"
    },
    employer: {
      eligibilityFiles: "Eligibility files",
      employees: "Employees"
    },
    employerBenefits: {
      tableHeadings: {
        carePlanUses: "Care Plan uses",
        category: "Category",
        links: "Links",
        totalMemberViews: "Total member views",
        uniqueClickThroughs: "Unique click-throughs",
        uniqueMemberViews: "Unique member views",
        vendorName: "Vendor name"
      }
    },
    employerBenefitsList: {
      reallyDelete: "Are you sure you want to delete this benefit?"
    },
    employerContractModifications: {
      addNew: "Add a modification",
      tableHeadings: {
        changes: "Changes",
        startDate: "Start date"
      }
    },
    employerContracts: {
      tableHeadings: {
        length: "Years",
        startDate: "Start date"
      }
    },
    employerHistory: {
      tableHeadings: {
        actor: "Actor",
        changes: "Changes",
        date: "Date"
      }
    },
    employerQbr: {
      consultCounts: {
        canceled: "Canceled",
        completed: "Completed",
        firstConsult: "First sessions",
        fourthPlusConsult: "Fourth+ sessions",
        secondConsult: "Second sessions",
        thirdConsult: "Third sessions",
        upcoming: "Upcoming"
      },
      countryCombinations: {
        count: "Count",
        countryTotal: "{country} total",
        employeeCountry: "Employee country",
        lovedOneCountry: "Loved one country",
        percent: "Percent"
      },
      dailyRegistrations: {
        heading: "Daily Registrations from {start} to {end}",
        tableHeader: {
          count: "Count",
          date: "Date"
        }
      },
      employeeAges: {
        average: "Average: {value}",
        maximum: "Maximum: {value}",
        minimum: "Minimum: {value}"
      },
      explanations: {
        countryCombinations:
          "Unknown data may occur if missing from the eligibility file or if the user has not provided Grayce with this information.",
        employeeCount:
          "This is the total number of employees for the employer, either from their eligibility file or as entered by us if an eligibility file was not provided. It does not include members who required manual approval.",
        employeesSignupRate:
          "The percentage of employees who created a Grayce account and became billable during the given time period.",
        employeesStartedConsults:
          "This is the number of employees where their first session was created during the given time period, regardless of when they signed up or when the session is scheduled for.",
        qbrExplainer:
          "Quarterly Business Review - a breakdown of what has happened with employer members during a specific business quarter."
      },
      heading: "QBR",
      nada: "--------",
      nps: {
        detractors: "Detractors",
        neutrals: "Neutrals",
        promoters: "Promoters",
        title: "NPS"
      },
      relationshipTypeCounts: {
        child: "Children: {count}",
        friend: "Friends: {count}",
        grandchild: "Grandchildren: {count}",
        grandparent: "Grandparents: {count}",
        myself: "Myself: {count}",
        other: "Other: {count}",
        parent: "Parents: {count}",
        parentInLaw: "Parents in law: {count}",
        relative: "Relatives: {count}",
        sibling: "Siblings: {count}",
        spouse: "Spouses: {count}"
      },
      relationshipTypeCountsHeader: {
        allPackageGrants:
          "The relationships of members who received an initial or renewal package grant during this time period",
        initialPackageGrants:
          "The relationships of members who received an initial package grant during this time period"
      },
      stats: {
        activityCount: "Care interactions",
        activityCountCategories: {
          ACCOUNT_CATEGORY: "Account: ",
          CARE_PLAN_CATEGORY: "Care Plan: ",
          COMMUNITY_CATEGORY: "Community: ",
          CONSULTS_CATEGORY: "Sessions: ",
          CONTENT_CATEGORY: "Content: ",
          DOCUMENTS_CATEGORY: "Documents: ",
          MARKETPLACE_CATEGORY: "Benefits: ",
          MESSAGING_CATEGORY: "Messaging: ",
          TRACKERS_CATEGORY: "Trackers: ",
          total: "Total: "
        },
        billableCounts: {
          categories: {
            renewalsAfterRefreshPeriodFirstDay: "Renewal - not automatic: ",
            renewalsOnRefreshPeriodFirstDay: "Renewal - automatic: ",
            signups: "First time signup: ",
            total: "Total: "
          },
          title: "Billable count"
        },
        billableEmployeeRate: "Percentage of employees who became billable",
        consultCounts: "Session counts",
        countryCombinations: "Country combinations",
        employeeAges: "Employee ages",
        employeeCount: "Employee count",
        employeeSignupRate: "Employee signup rate",
        employeesStartedConsults: "Employees starting sessions",
        multipleJourneyEmployeesCount: "Employees with multiple journeys",
        relationshipTypeCounts: "Relationship types"
      },
      summaryDescription:
        "There were {count, plural, one {# journey} other {# journeys}} created during this time period that have been onboarded and have a published Care Plan. The table below uses AI to summarize the categories of topics covered in those journeys.",
      summaryHeading: "Support summary for {count, plural, one {# journey} other {# journeys}}",
      summaryTable: {
        category: "Category",
        description: "Description",
        journeyCount: "Journey count"
      },
      surveyResponses: {
        heading:
          "Survey responses - Count: {count}, Average rating: {average}, Post-session average: {typeformAverage}, In-app average: {delightedAverage}",
        services: {
          delighted: "Delighted",
          nps: "NPS",
          typeform: "Typeform"
        }
      },
      surveyTableHeadings: {
        comments: "Comments",
        date: "Date",
        score: "Score",
        service: "Service"
      },
      tableHeadings: {
        allTime: "All time",
        limited: "Limited",
        stat: "Stat"
      }
    },
    employers: {
      delete: {
        deleteEmployer: "Delete {name}",
        deleteEmployerDisabled: "{name} has members and cannot be deleted."
      },
      eligibilityFiles: "Manage and apply new eligibility files",
      eligibilityList: "List of eligible employees",
      reallyDelete: "Are you sure you want to delete this employer?",
      tableHeadings: {
        ACTIONS: "Actions",
        CLIENT_COUNT: "All-time new signups",
        CREATED_AT: "Created at",
        CURRENT_BILLABLE_CLIENTS: "Current period billable members",
        ELIGIBILITY_METHOD: "Eligibility method",
        EMPLOYEES_PURCHASED: "Current period seats",
        MINUTES_PER_CLIENT: "Minutes per member",
        NAME: "Name",
        REFRESH_PERIOD_BEGIN_DATE: "Current refresh period begin date",
        REFRESH_PERIOD_END_DATE: "Current refresh period end date"
      }
    },
    experts: {
      archive: "Archive {name}",
      archiveDisabled: "{name} has active roles and cannot be archived.",
      editExpert: "Edit {name}",
      expertManager: "Care Partner is a Care Partner manager.",
      reallyDelete: "Are you sure you want to archive {name}?",
      tableHeadings: {
        actions: "Actions",
        client_count: "Members",
        coverage: "Coverage",
        created_at: "Created at",
        email: "Email",
        journey_assignment_weight: "Assignment weight",
        manager: "Manager",
        name: "Name",
        specialties: "Specialties"
      }
    },
    goalTemplates: {
      addGoal: "New goal"
    },
    internalNoteTemplates: {
      internalNoteTemplate: "New template",
      internalNoteTemplates: "Internal note templates",
      newInternalNoteTemplate: "New internal note template"
    },
    journeyEligibility: {
      tableHeadings: {
        approver: "Approver",
        createdAt: "Creation date",
        decision: "Decision",
        employer: "Employer",
        enteredEmployer: "Entered employer",
        notes: "Notes"
      }
    },
    journeyTaggings: {
      reallyDelete: "This tag will be removed from {name}'s journey. Are you sure you want to delete it?",
      tableHeadings: {
        CARE_PROFILE_NAME: "Loved one",
        DATE_ADDED: "Date added",
        DATE_ARCHIVED: "Date archived",
        OWNER_CLIENT_NAME: "Member",
        OWNER_EMPLOYER_NAME: "Employer",
        STATUS: "Status"
      }
    },
    journeyTags: {
      mergeModalTitle: "Edit or merge tags",
      reallyDelete: "This tag will be removed from all journeys. Are you sure you want to delete it?",
      tableHeadings: {
        ACTIVE_COUNT: "Active",
        ARCHIVED_COUNT: "Archived",
        TAG: "Tag name",
        TOTAL_COUNT: "Total"
      }
    },
    journeys: {
      caregiverInfo: "{name}: ",
      caregiverRoles: {
        owner: "Owner",
        team_member: "Team member"
      },
      tableHeadings: {
        assessment: "Assessment",
        care_plan_published: "Care Plan published",
        client_count: "Caregivers",
        completed_consults: "Completed sessions",
        created_at: "Created",
        email: "Owner email",
        expert: "Care Partner",
        hours: "Owner balance",
        initial_consult_complete: "Initial session report complete",
        loved_one: "Loved one",
        name: "Owner name",
        owners_employer: "Owner's employer",
        owners_relationship: "Owner's relationship"
      }
    },
    messageTemplates: {
      messageTemplateCategories: "Message template categories",
      messageTemplates: "Message templates"
    },
    myStory: {
      preview: "Preview"
    },
    oidcProviders: {
      reallyDelete: "Are you sure you want to delete this SSO connection?",
      tableHeadings: {
        actions: "Actions",
        employer: "Employer",
        host: "Host",
        name: "Name"
      }
    },
    outOfOffice: {
      addOutOfOffice: "Add out of office event",
      disclaimer:
        "Days represent the number of days a Care Partner has an OOO event in this application. Keep in mind, the total may not represent exact time off as Care Partners take partial days.",
      expertTeams: "Care Partner teams",
      outOfOffice: "Care Partner OOO",
      totalDays: "Total days",
      upcomingDays: "Upcoming days",
      ytdDays: "YTD days"
    },
    packageGrant: {
      clientInfo: "Member Info",
      dataHeaders: {
        approver: "Approver",
        approverType: "Approver type",
        createdAt: "Created at",
        decision: "Decision",
        eligibilityMethod: "Eligibility method",
        employer: "Employer",
        enteredEmployer: "Entered employer",
        firstEligibleAt: "First eligible at",
        initialOrRefresh: "Initial or Refresh",
        kind: "Type",
        name: "Name",
        notes: "Notes",
        personalEmail: "Personal email",
        signedUpAt: "Signed up at",
        workEmail: "Work email"
      },
      eligibilityApproverType: {
        Admin: "This package was manaully approved by the admin listed above",
        Client:
          "This package was approved automatically due to the employee matching the eligibility file, confirming their work email, or purchasing the package.",
        Employer:
          "This package was approved automatically due to the employee matching the eligibility file or confirming their work email.",
        Expert:
          "This package was manually approved the Grayce Care Partner listed above. Care Partners can comp time for their own clients."
      },
      employerInfo: "Employer Info",
      initial: "Initial",
      kind: {
        CLIENT_PURCHASE: "Purchased by Member",
        COMPED_TIME: "Comped by Grayce",
        EMPLOYER_ALLOCATION: "Employer Allocation"
      },
      linkedInSearch: "Find on LinkedIn",
      otherPackageGrants: "Previous Package Grants",
      packageInfo: "Package Info",
      refresh: "Refresh"
    },
    packageGrants: {
      pendingBillableCap: "Pending {employer} billable cap",
      pendingLiveContract: "Pending {employer} live contract",
      tableHeadings: {
        CREATED_AT: "Package added at",
        ELIGIBILITY_METHOD: "Eligibility method",
        ELIGIBILITY_STATUS: "Status",
        EMPLOYER: "Employer",
        FIRST_ELIGIBLE_AT: "First eligible at",
        NAME: "Member name"
      }
    },
    peerSupportRequests: {
      tableHeadings: {
        email: "Email",
        from: "From",
        id: "Id",
        message: "Message",
        sentAt: "Sent at"
      }
    },
    refreshPeriods: {
      addCredit: "Add credit",
      periodDescription: "{startDate} - {endDate}",
      tableHeadings: {
        billableClients: "Billable member count",
        createdAt: "Timestamp",
        employees: "Seats added",
        endDate: "End date",
        enrollmentCode: "Enrollment code",
        startDate: "Start date"
      }
    },
    taggings: {
      download: "Export CSV"
    },
    tags: {
      bulkMerge: "Bulk merge",
      download: "Export CSV",
      generate: "Generate recommended tags",
      generateDisabled: "Fill in the field(s) {values} before using this feature",
      generateHelp:
        "With the power of AI, this uses our list of tags and the content fields {values} to generate recommended tags"
    },
    webinar: {
      preview: "Preview",
      registrations: "Registrations"
    },
    webinarRegistrations: {
      member: "Member",
      prospect: "Prospect",
      reallyDelete:
        "Are you sure you want to cancel this registration. The user will be notified that their registration has been canceled.",
      tableHeadings: {
        employer: "Employer",
        name: "Name",
        registrationTime: "Registered at",
        startTime: "Start time",
        type: "Type"
      }
    },
    worksheetTemplates: {
      archive:
        'Are you sure you want to archive "{title}"? This will remove the worksheet from the Care Partner template options. This can be undone.',
      createTemplate: "Create worksheet",
      deleteItem: "Are you sure you want to delete {title}?",
      descriptionLabel: "Description",
      edit: "Edit worksheet",
      editingItem: "Editing worksheet item",
      editingWarning:
        "Do NOT change the meaning of questions while editing worksheet items. If this needs to be done, delete the worksheet item and add a new one",
      newItem: "New worksheet item",
      newWorksheetTemplate: "New worksheet",
      previewLabel: "Preview",
      reallyArchive: "Really archive?",
      reallyUnarchive: "Really unarchive?",
      tableHeadings: {
        actions: "Actions",
        author: "Author",
        created_at: "Date added",
        focus_areas: "Focus area(s)",
        tags: "Tags",
        title: "Title",
        updated_at: "Last updated",
        used_count: "Times used"
      },
      tagsLabel: "Tags",
      titleLabel: "Title",
      unarchive:
        'Are you sure you want to unarchive "{title}"? This will add the worksheet to the Care Partner template options. This can be undone.',
      worksheetTemplates: "Worksheets"
    }
  },
  appUpdated: {
    mobile: {
      message:
        "You're missing out on new features and critical fixes. Please update your app to the latest version to make sure things keep working properly.",
      upgradeButton: "Upgrade"
    },
    web: {
      applyButton: "Update",
      applyUpdateMessage:
        "We've updated the app with new features. Please click the button to reload the page with the latest changes. If you're currently working on something, you should save or finish your work before updating."
    }
  },
  article: {
    activateToExplore: "Activate your free benefit to explore",
    allArticles: "All articles",
    articles: "Articles",
    exploreAllEvents: "Explore all events",
    hostedBy: "Hosted by ",
    learnMore: "Learn more",
    liveEvent: "Online event",
    minuteRead: "{minutes} min read",
    minuteWatch: "{minutes} min watch",
    reallyDelete: "Are you sure you want to delete this article?",
    registered: "You're Registered",
    relatedContent: "Related content",
    request: "Request",
    requestGrayceBenefits: "Request Grayce benefits",
    signUpMessage:
      "We understand the complex world of caregiving and can help you navigate the logistics, whatever your situation or location. Sign up for Grayce today.",
    signUpOrLogIn: "Sign up or log in",
    thisEventHasEnded: "This event has ended! Explore our upcoming events and find more opportunities to connect.",
    thisEventHasEndedNoUser:
      "This event has ended! Sign up to explore our upcoming events and find more opportunities to connect.",
    your: "your "
  },
  assignmentWeightExplanation: {
    aFewNotes: "A few notes on this process:",
    heading: "How is assignment weight used?",
    note1:
      "If any of the filters above removes all of the Care Partners, that filter will be discounted and we go back to the Care Partners from the previous step",
    note2:
      "Because of the number of steps applied before the weighting is used, it's likely that the weights are actually applied only very rarely",
    note3:
      "Care Partners can have multiple types of specialties, but the only ones used for member assignment are the age ones",
    note4: "The order in which the age specialties are entered does not matter",
    p1: "Assignment weight is used to determine the likelihood of selecting a Care Partner for member assignment when there are multiple Care Partners remaining after other criteria are applied. The process for choosing Care Partners consists of several steps that narrow down the selection, as follows:",
    step1: "Start with every Care Partner in the application",
    step2: "Remove any Care Partners who have an assignment weight of zero. These will never receive assignments",
    step3: "Remove any Care Partners who are currently in an OOO period",
    step4:
      "If the member has selected an age for the loved one on their journey, limit the selection to Care Partners who have a specialty for that age range",
    step5:
      "If the member has selected their timezone, limit the selection to Care Partners who cover that area. The areas used are US East, US West, Europe, and Asia",
    step6:
      "After that process, if there are still multiple Care Partners remaining the assignment weights are used to determine the probability of selecting each. For example, if the remaining Care Partners have weights of 90 and 10 then there will be a 90% chance of selecting the first one and a 10% chance of the other",
    title: "Assignment weight"
  },
  careJourney: {
    almostThere: "Almost there!",
    careAndSupport: "Care & support",
    careJourney: "Care journey",
    carePlan: "Care Plan",
    connect: "Connect",
    consults: "Sessions",
    contentForYou: "Content just for you",
    editNextConsult: "Edit next session date",
    editStage: "Edit stage",
    finishProfile: "Finish profile",
    getStarted: "Get started",
    grayceExplanation:
      "Grayce is your Care Partner guide and concierge, here to help you navigate care for your aging, ill, and vulnerable loved ones.",
    greatStart: "Great start! Keep going.",
    initialConsultScheduled: "Your initial session is scheduled for {date}",
    join: "Join with video",
    learn: "Learn",
    learnMore: "Learn more",
    less: "Less",
    messages: "Messages",
    more: "More",
    myExpert: "My Care Partner",
    nextConsult: "Your next session is {date}",
    openGoals: "{goalsCount, plural, one {# open goal} other {# open goals}}",
    personalize: "Personalize",
    personalizeExperience: "Personalize your experience",
    resources: "Resources",
    scheduleConsult: "Schedule session",
    scheduleNextConsult: "Schedule your next session",
    stage: "Care journey stage",
    unpublished: {
      hiIm: "Hi! I'm {expertName}, your Grayce Care Partner.",
      myStory: "About your Care Partner",
      whatGrayceIs:
        "Think of Grayce as your Care Partner guide and concierge. We're here because you care. We help you to navigate needs for aging, ill, and vulnerable loved ones. Whether you're planning care for yourself or a loved one, supporting ongoing needs, or navigating transitions, Grayce is here to guide and support you through every step of your care journey.",
      whatIsGrayce: "What is Grayce?",
      whySchedule: "Why schedule a session?",
      whyScheduleDescription:
        "Care needs are complex, and can be challenging.  To best support you and personalize your experience, it’s important that your Grayce Care Partner fully understands your circumstances and needs.  The session can be brief, if you wish.  We are confident you’ll feel better after a conversation, too."
    },
    unreadMessages: "{count, plural, one {# unread message} other {# unread messages}}",
    whatIsGrayce: "What is Grayce?"
  },
  carePlan: {
    addTask: "Add task",
    autoAdded: " (Auto-Added)",
    base: {
      actionItems: {
        add: "Add member task",
        searchPlaceholder: "Search member tasks...",
        tableHeaders: {
          assign: "Assign to",
          name: "Name"
        }
      },
      all: {
        return: "Return to Care Plan"
      },
      articles: {
        add: "Recommended media",
        description: "Your Grayce Care Partner has identified these personalized resources",
        searchPlaceholder: "Search media...",
        title: "Picked for you"
      },
      carePlan: "Care Plan",
      carePlanFor: "Care Plan for {name}",
      employerBenefit: {
        searchPlaceholder: "Search benefits..."
      },
      expert: {
        consultReport: "Session on {date}",
        edited: "(edited on {date})",
        initialConsultReport: "Initial session on {date}",
        internalNotesDate: "Internal note on {date}",
        logInitialSession: "Log initial session",
        logSession: "Log session",
        noteSections: {
          NOTE: "Internal notes",
          ROADMAP: "Plan",
          SYNOPSIS: "Synopsis"
        },
        searchNotes: "Search notes",
        sessionDetails: "Session details",
        sessionHistory: "Session history",
        timeLog: "Time log",
        timeLogHistory: "Time log history",
        timeLogSubmitted: "Time log submitted",
        title: "Title...",
        typeNotesHere: "Type notes here...",
        versionHistoryDate: "Version on {date}",
        versionTitle: {
          NOTE: "(no title)",
          ROADMAP: "Plan Version",
          SYNOPSIS: "Synopsis Version"
        }
      },
      expertServices: {
        add: "Add Care Partner task",
        addResources: "Add resource",
        description: "Save time and energy with our help",
        editResources: "Edit resources",
        emptyDescription:
          "Your Grayce Care Partner will add tasks they are working on for you after you connect with them. {schedule} a session or send them a {message} to get started.",
        message: "message",
        messageUpper: "Message",
        mustHaveResources: "Add at least one resource to change this task to complete",
        schedule: "Schedule",
        scheduleSession: "Schedule session",
        searchPlaceholder: "Search Care Partner tasks...",
        see: "See Care Partner services",
        statuses: {
          completed: "Complete",
          in_progress: "In progress",
          updated: "Updated",
          updating: "Updating"
        },
        statusesLower: {
          completed: "View resource",
          in_progress: "We're working on it",
          updated: "Resource updated"
        },
        supportStarts: "Support starts with a single call",
        supportStartsDescription:
          "Your Care Partner can save you the time and headache of vetting, recommending, and completing the following tasks after you connect with them.",
        tableHeaders: {
          name: "Name",
          status: "Status",
          updated: "Updated",
          view: "View"
        },
        title: "What your Care Partner is working on",
        view: "View details",
        viewResource: "View resource",
        workingOnIt: "We're working on it"
      },
      expertView: "Expert view",
      goals: {
        active: "Active",
        add: "Set a goal",
        addAGoal: "Add a goal",
        addGoal: "Add goals",
        addGoalToCarePlan: "Add goal to Care Plan",
        all: "All goals",
        archive: "Archive",
        associatedTasks: {
          no: "No, complete this goal without finishing all the tasks",
          title: "Would you like to complete all the tasks associated with this goal?",
          yes: "Yes, mark all tasks as complete"
        },
        browse: "Browse preset goals",
        browseLibrary: "Browse the goal library",
        browseLibraryDescription:
          "Setting intentional goals is the first step to progress. And you can always change them.",
        delete: "Delete",
        description: "Simplify life with these concrete action plans",
        draft: "Draft",
        editMode: "Edit mode",
        editModeGoalBannerText: "Sit tight! Your Care Partner is updating information in this goal.",
        editModeGoalsBannerSubtext: "You will get an email when your Care Plan is updated",
        editModeGoalsBannerText: "Sit tight! Some of this information might have changed since your last session.",
        editModeWrapped: "(edit mode)",
        empty: "As you and your Care Partner add more goals, they will appear here.",
        goToPage: "Go to page",
        goalModule: "Goal module",
        goals: "Goals",
        markComplete: "Mark complete",
        markIncomplete: "Mark incomplete",
        memberGoals: "Member goals",
        notPublished: "Not published",
        publish: "Publish",
        publishAll: "Publish all?",
        publishGoal: "Publish goal",
        publishTo: "Publish to goal",
        published: "Published",
        reallyDelete: "Are you sure you want to permanently delete this goal?",
        reorder: "Reorder",
        searchMore: "Search more results",
        searchPlaceholder: "Search goal library...",
        set: "Set a goal",
        showLess: "Show less",
        showMore: "Show more",
        title: "Your goals",
        typeGoalName: "Type goal name...",
        typeTaskName: "Type task name...",
        unaddedGoals: "Unadded goals",
        unarchive: "Unarchive",
        unpublished: "Unpublished",
        viewAll: "View all of your goals"
      },
      memberView: "Member view",
      nothingHere: "Nothing here",
      pins: {
        description: "Review your pinned content and resources",
        title: "Your pins",
        titles: {
          articles: "Media",
          employerBenefits: "Benefits",
          fileResources: "Documents",
          resources: "Care Partner resources",
          tools: "Tools"
        },
        viewDetails: "View details"
      },
      submit: "Submit Plan",
      tools: {
        add: "Include a tool",
        browse: "Browse tool library",
        description: "Document preferences and stay organized",
        emptyDescription:
          "Worksheets and more will be added here based on your Goals. You can also browse tools in the {explore}.",
        exploreSection: "Explore section",
        searchPlaceholder: "Search tools...",
        see: "See your tools",
        tableHeaders: {
          last_accessed: "Last accessed",
          name: "Name",
          status: "Status",
          view: "View"
        },
        title: "Tools",
        trackers: "Trackers",
        worksheets: "Worksheets"
      },
      welcome: "Welcome to Grayce, {firstName}",
      welcomeBack: "Welcome back, {firstName}",
      yourGoals: "Your goals"
    },
    browseGoals: "Browse goals",
    canceled: "Canceled",
    carePlanCount: "{count, plural, one {Showing # Care Plan} other {Showing # total Care Plans}}",
    carePlanLastUpdated: "Care Plan last updated: {date}",
    client: {
      caringFor: "Caring for "
    },
    completedThemAll: "You've completed them all!",
    delivered: "Delivered on {completedAt}",
    downloadSavedResources: "Download saved resources",
    draftActionItems: "Draft member tasks",
    draftClientVisibleTasks: "Your draft tasks",
    draftConsultDate: "Draft next recommended session date: {date}",
    draftConsultDateEmpty: "Add next recommended session date",
    draftCount: "+{count} draft",
    draftResources: "Draft resources",
    due: "Due: {date}",
    emptyStates: {
      consultBooked: {
        description:
          "At your upcoming meeting with your Care Partner, you’ll talk in detail about your situation. Your Care Partner will listen closely and ask questions to understand how they can help. There’s no need for you to prepare, but if you have questions, bring them.",
        title: "Your session is booked!"
      },
      cpInProgress: {
        description:
          "You completed your first session. Your Care Partner is working on your next steps. Sit tight, and we'll send you an email as soon as it's ready.",
        title: "Kudos!"
      },
      expertTasks: {
        description:
          "We'll do the time-consuming research, make the tedious phone calls, or handle the paperwork. In short, we can navigate any tricky situation so you don't have to. Book a session to start offloading tasks and see them here.",
        title: "We can help."
      },
      noConsult: {
        description:
          "It can be hard to know where to start. Schedule a session with your Care Partner for personalized guidance or browse goals to explore what’s important to you.",
        title: "Your list is empty."
      },
      noHours: {
        description:
          "You have used up your employer-subsidized hours. {purchaseHours} for next steps and personalized guidance.",
        title: "You are out of hours."
      },
      noTasks: {
        description: "Let's keep going. Message your Care Partner for personalized guidance on next steps.",
        title: "All done!"
      }
    },
    expertServices: {
      agenda: "Agenda from last session",
      canHelp: "We can help.",
      guide: {
        description:
          "Navigating care can be tricky, but with your Grayce Care Partner as your guide, you can avoid common pitfalls and see what’s ahead. We can compare costs, handle referrals, or help with a transition.",
        title: "Simplify and guide"
      },
      hideServices: "Hide common services",
      letUsHelp: "Let us help.",
      recommendedNextConsult: "Recommended next session: {date}",
      research: {
        description:
          "Whether you need a cleaning service, lawyer, doctor, or something else, Grayce Care Partners can find it. Let us vet and recommend the best services, saving you the headache.",
        title: "Research and recommend"
      },
      saveTime: {
        description:
          "Grayce Care Partners can save you time by arranging rides, scheduling appointments, setting up food delivery services, and much more!",
        title: "Save time"
      },
      saveYouTime:
        "Grayce Care Partners can save you time, vet and recommend services, and guide you through your journey.",
      scheduleConsult: "Schedule session",
      viewHistory: "View session history",
      viewServices: "View common services"
    },
    goal: "Goal: {goal}",
    goalReallyDelete: 'Are you sure you want to delete "{title}"?',
    goalTemplate: " Goal: {goal}",
    hereForYou: "I'm here for you.",
    hideHistory: "Hide history",
    lastConsult: "Last session: {date}",
    lastUpdated: "Last updated: {date}",
    learn: {
      personalize: "Personalize",
      skipQuestion: "Skip question",
      tellUsMore: "Tell us more and we can find content relevant to you",
      viewMore: "View more"
    },
    nextConsultDate: "Next recommended session date: {date}",
    no: "No",
    openInteractiveDocument: "Open interactive document",
    popoverTitles: {
      delete: "Delete",
      edit: "Edit",
      publish: "Publish"
    },
    priorityLine: "PRIORITY LINE",
    publishCarePlan: "Publish Care Plan",
    publishDraftClientVisibleTasks: "Publish all draft tasks",
    publishDraftResources: "Publish all draft resources",
    publishDraftTasks: "Publish all draft tasks",
    reassignTask: "Reassign task",
    recommendedNextConsult: "Suggested next session: {date}",
    removeGoal: "Remove goal",
    scheduleConsult: "Schedule a session",
    sectionTitles: {
      actionItems: {
        description: "Complete these actions to achieve your goal",
        title: "Your next steps"
      },
      connect: "Connect",
      expertServices: {
        description: "Save time and energy with our help",
        title: "What your Care Partner is working on"
      },
      handlingIt: "We're handling it",
      howExpertHelp: "How your Care Partner can help",
      learn: {
        description: "Your Grayce Care Partner has identified these personalized resources",
        title: "Recommended for you"
      },
      nextSteps: "Your next steps",
      savedResources: "Saved resources",
      tools: {
        description: "Document preferences and stay organized",
        title: "Tools"
      },
      toolsCareTeam: "Care team",
      toolsConsentForm: "Consent form",
      toolsHomeSafety: "Home safety",
      toolsLegalDocuments: "Important legal documents",
      toolsMedicationTracker: "Medication tracker",
      toolsProviderContacts: "Provider contacts",
      toolsWorksheets: "Worksheets"
    },
    sections: {
      archive: {
        title: "Archive"
      },
      care_plan: {
        description: "Simplify life with step-by step expert guidance",
        title: "Care Plan"
      },
      care_team: {
        description: "Include others who help care for your loved one",
        title: "Care team"
      },
      drive: {
        description: "Store and upload documents",
        selectedFolder: "{folderTitle}",
        title: "Drive",
        yourDocuments: "Your documents"
      },
      getInTouch: "Need to follow up on an existing plan or discuss something new? Let's get in touch.",
      goal_library: {
        title: "Goal library"
      },
      menu: {
        title: "Menu"
      },
      profile: {
        description: "Personal details about your loved one",
        title: "Profile"
      },
      scheduleSession: "Schedule a Session",
      trackers: {
        description: "Keep track of contacts, medication, legal docs",
        title: "Trackers"
      },
      worksheet: {
        title: "Worksheet"
      }
    },
    seeCompletedTasks: "See {count, plural, one {# completed task} other {# completed tasks}}",
    showHistory: "Show history",
    supportConnection: "1-on-1 support connection",
    supportDescription:
      "Caregiving can be incredibly isolating. Sign up and we'll match you with another caregiver for mutual support.",
    tabs: {
      goals_complete: "Completed",
      goals_incomplete: "Care Plan",
      pins: "Pins"
    },
    task: {
      chartView: "Compare",
      listView: "View details",
      notVisible: "NOT VISIBLE TO MEMBERS",
      publishResource: "Publish resource",
      publishTask: "Publish task only",
      publishTaskAndResources: "Publish task and all resources",
      reallyDelete: "Are you sure you want to delete this service?",
      resources: {
        addNotesAbout: "Add notes about this resource...",
        addResource: "Add resource",
        downloadResources: "Download resources",
        editFeatureCriteria: "Edit feature criteria",
        hideFeatures: "Hide details",
        lastUpdated: "Last updated: {date}",
        markedNotUseful: 'Resources marked "not useful"',
        markedNotUsefulDescription:
          'Resources that are marked "not useful" won’t be accessible and won’t display from the chart view.',
        messageYourExpert: "Message your Care Partner",
        noResources: "No resources added",
        notUseful: "Not useful",
        pin: "Pin resource",
        reallyDeleteResource: "Are you sure you want to delete {title}?",
        unpin: "Unpin resource",
        useful: "Useful",
        viewFeatures: "View details"
      }
    },
    tools: {
      medications: {
        introParagraph1:
          "Track your loved one's medications to stay organized, ensure safety, and simplify information sharing.",
        introParagraph2:
          "We'll ask about prescription and over-the-counter medications, vitamins and supplements. Fill in the information you have. You can always make updates later.",
        overTheCounterCount: "Over the counter",
        prescriptionCount: "{count, plural, one {Prescription} other {Prescriptions}}",
        vitaminAndSupplementCount: "Vitamins and supplements"
      },
      providers: {
        introParagraph1: "Track your loved one's medical providers to stay organized and simplify information sharing.",
        introParagraph2:
          "We'll ask about their primary care provider, other doctors, and other care providers. Fill in the information you have. You can always make updates later.",
        otherCareProviderCount: "{count, plural, one {Other care provider} other {Other care providers}}",
        otherDoctorCount: "{count, plural, one {Other doctor} other {Other doctors}}",
        primaryCareProviderCount: "{count, plural, one {Primary care provider} other {Primary care providers}}"
      },
      safety: {
        lastCompleted: "Last completed {date}"
      }
    },
    unpublished: {
      carePlanDescription:
        "A Care Plan is a personalized roadmap with steps and resources to help meet your goals. As your care journey changes, we'll update your Care Plan to reflect your needs.",
      expertIsWorking:
        "{expertName} is working on your Care Plan. You will receive an email notification when it is ready.",
      getStarted: "Get started",
      littleInformation:
        "Looks like we still need a little information before we can create a personalized Care Plan for you.",
      nowOffers:
        "Grayce now offers a customized Care Plan to all users! A Care Plan helps take to-dos off your plate and gives you personalized resources. To get started, we need a little more information to create a personalized Care Plan for you.",
      personalizedCarePlan: "Personalized Care Plan",
      title: "Care Plan",
      whatIsCarePlan: "What is a Care Plan?"
    },
    unreadResources: "{member} has unread published resources: {titles}",
    unreadResourcesMember: "You have new resources",
    viewActivity: "View activity",
    viewByGoals: "View by goals",
    viewByTasks: "View by tasks",
    yes: "Yes",
    yourCarePlan: "{careProfileName}'s Care Plan",
    yourDraftTasks: "Your draft tasks"
  },
  carePlanActionItem: {
    begin: {
      complete_consent_form: "Begin the consent form",
      complete_legal_document_tracker: "Begin adding legal documents",
      complete_medication_tracker: "Begin adding medications",
      complete_provider_list: "Add providers"
    },
    continue: {
      complete_consent_form: "Continue the consent form",
      complete_legal_document_tracker: "Continue adding legal documents",
      complete_medication_tracker: "Continue adding medications",
      complete_provider_list: "Add providers"
    },
    publishItem: "Publish member task",
    reallyDelete: "Are you sure you want to delete this next step?",
    view: {
      complete_consent_form: "View consent form",
      complete_legal_document_tracker: "View legal documents",
      complete_medication_tracker: "View medications list",
      complete_provider_list: "View providers list"
    }
  },
  carePlanTaskTemplate: {
    exampleName: "James"
  },
  carePlanTaskTemplates: {
    carePlanTaskTemplate: "Task template",
    carePlanTaskTemplates: "Task templates",
    createTemplate: "Create template",
    ctaLabel: "Assessment",
    edit: "Edit template",
    focusAreaLabel: "Focus area(s)",
    previewLabel: "Preview",
    reallyDelete: "Are you sure you want to delete {title}?",
    tableHeadings: {
      actions: "Actions",
      author: "Author",
      created_at: "Created at",
      cta: "Assessment",
      focus_areas: "Focus area(s)",
      tags: "Tags",
      title: "Title",
      updated_at: "Updated at",
      user_type: "User type"
    },
    tagsLabel: "Tags",
    titleLabel: "Title",
    userTypeLabel: "User type"
  },
  careProfile: {
    basicInfo: "About the care recipient",
    chooseAllThatApply: "(choose all that apply)",
    currentSituation: "How can Grayce help you care for this person?",
    deleteSubObjectChecking: "Just checking",
    deleteSubObjectMessage: "Are you sure you want to delete this? You won't be able to undo this operation.",
    documentationSections: {
      notes: "Notes",
      plan: "Plan",
      sessions: "Sessions",
      synopsis: "Synopsis"
    },
    expertOnly: "Questions only visible to the Care Partner",
    fromYourTimezone: "({difference} hours from your timezone)",
    languageInterpretation: "Will you need language interpretation on live sessions?",
    lastConsult: "Last session: {date}",
    modes: {
      documentation: "Documentation",
      history: "History",
      profile: "Profile data"
    },
    notes: "Notes",
    primaryAreasOfConcern: "Primary areas of concern",
    providerContacts: "Provider contacts",
    range1: "1 (not at all) out of 5",
    range2: "2 (negative) out of 5",
    range3: "3 (neutral) out of 5",
    range4: "4 (positive) out of 5",
    range5: "5 (extremely) out of 5",
    relationship: "Relationship to care recipient: {relationship}",
    sectionTitles: {
      careAndHome: "Care and home",
      demographics: "Demographics",
      financial: "Financial",
      legal: "Legal",
      medical: "Medical",
      memberData: "Member data",
      other: "Other",
      risk: "Risk",
      social: "Social",
      useCaseAndRisk: "Use case and risk"
    },
    timezone: "Timezone: ",
    topicOfInterest: "Topic of interest selected during activation",
    updateUseCase: "Update use case",
    updateUseCaseWarning:
      "Warning: changing the use case on a journey will remove all care profile data that is no longer relevant to the new use case because those pieces of data are no longer valid in the new situation. Take care when changing this to ensure that data is not lost by mistake."
  },
  client: {
    account: {
      account: "Account",
      accountDeletion: "Account deletion",
      accountDetails: "Account details",
      accountHistory: "Account history",
      accountInfo: "Your Account",
      addAJourney: "Add a journey",
      additionalHoursRemaining: "Additional support hours remaining:",
      carePlanPackage: "Care Plan package:",
      changePassword: "Change password",
      complete: "Complete",
      confirmDeletion: {
        deleteButton: "I want to delete my account",
        heading: "Delete your acount",
        postamble:
          "Your data will be permanently deleted, and this request is not reversible. Clicking the delete button will log you out of Grayce and schedule your account to be deleted within 24 hours. You will receive an email when your account has been successfully deleted.",
        preamble:
          "Are you sure you want to delete your account? Doing so will remove all record of your account from Grayce, including but not limited to:",
        whatGetsDeleted1: "All data about your account and your care journey(s)",
        whatGetsDeleted2: "Any purchases you have made within Grayce",
        whatGetsDeleted3: "The accounts of anyone you have invited to use the app and share your account"
      },
      consultReminderEmailsLabel: "Receive text reminders for session appointments.",
      contact: "Contact",
      cpPlus: "Care Plan package",
      dangerZone: "Danger zone",
      deleteMyAccount: "Delete my account",
      deleteMyAccountExplanation: "Deleting your account is a permanent way to delete your account data from Grayce.",
      description: "A look at your personal details.",
      editAccount: "Edit account",
      emailPreferences: "Email preferences",
      emailPreferencesDescription: {
        p1: "Manage your email preferences and customize the visibility of message content in your notification emails.",
        p2: "Choose how much of the message is displayed to suit your needs. Additionally, you can opt to receive individual emails for real-time updates or a daily digest."
      },
      expertZeroBalanceNoNewJourney:
        "The owner of this journey has a balance of zero minutes, so journeys cannot be added to their account.",
      expired: "Expired",
      expiresOn: "These hours expire on {date}",
      extend: "Extend your Grayce services",
      faq: "FAQ",
      goToMyAccount: "Go to my account",
      hourBalance: "Hour balance",
      hoursRemaining: "Hours remaining:",
      hoursUsed: "Hours used to date",
      inProgress: "In progress",
      labels: {
        email: "Email",
        name: "Name",
        phone: "Phone",
        residence: "Residence",
        timezone: "Timezone"
      },
      lowBalance:
        "Your account balance is low. To extend your services and continue to work with your Grayce Care Partner, purchase hours below or reach out to your employer to request more hours.",
      marketingEmailsLabel: "Stay updated on the latest services, information, and personally relevant offers.",
      nHours: "{count, plural, one {# hour} other {# hours}}",
      otherActivity: "Other activity",
      packageEducation: {
        content1:
          "A care planning package includes the initial session with your Grayce Care Partner and the creation of your individualized Care Plan. Your Grayce Care Partner will create your Care Plan to support you through your caregiving journey with resources and continual support.",
        content2:
          "Care planning hours can be used towards additional support from your Grayce Care Partner for consulting, researching, and coordinating ongoing caregiving needs.",
        heading1: "What is a care planning package?",
        heading2: "How can my hours be used?"
      },
      packagePurchaseOptionsExplainer:
        "Purchase one hour for a little help, or purchase a package for ongoing support at a discounted rate.",
      packageSummary: "Package summary",
      privacy: "Privacy",
      privacyAndAccount: {
        sections: {
          account: "Account",
          activePackages: "Your active packages",
          changePassword: "Change password",
          deleteAccount: "Delete account",
          emailPreferences: "Email preferences",
          marketingAndSms: "Marketing and text reminders",
          notifications: "Notifications",
          preferences: "Preferences",
          privacy: "Privacy",
          remainingBalance: "Remaining balance: ",
          resendVerification: "Resend work verification email",
          resendVerificationCta: "Resend verification",
          twoFactor: "Two factor authentication",
          unverifiedHourEmployers: "Employer — {employers}",
          unverifiedHoursAvailable: "Verify your work email to claim your free benefit"
        }
      },
      purchaseOption180: {
        description:
          "Save 8%! Our suggested package includes 3 hours — for when you need a little extra help with a new or evolving need."
      },
      purchaseOption420: {
        description:
          "Our best offer. This package includes 7 hours - best for those looking for holistic support across a variety of issues."
      },
      purchaseOption60: {
        description: "This package includes 1 hour — best for a quick check in for an ongoing issue."
      },
      purchaseSuccess: "A new package has been added to your account.",
      referACoworker: {
        emailPlaceholder: "Coworker's email",
        inputLabel: "Enter your coworker's name and work email to send them an invitation to Grayce.",
        invitations: {
          activation: "activation(s)",
          earned: "Earned",
          inviteSent: "Invite sent!",
          pending: "Pending",
          thanksForSharing: "Invite more coworkers and spread the love ❤️",
          title: "Invitations"
        },
        messageInputLabel: "Message (optional)",
        messagePlaceholder:
          "Hey {name}, did you know we have a free benefit that supports family care? I started using Grayce and thought you might be interested.",
        namePlaceholder: "Coworker's name",
        referralLink: {
          copied: "Referral link copied",
          description: "You can also share your referral link by copying and sending or sharing it.",
          title: "Share a referral link"
        },
        title: "Share Grayce with coworkers"
      },
      reportProblem: {
        another: "Submit another issue",
        description:
          "Thank you for reporting the issue. Our team is reviewing your submission and will contact you as soon as possible with further updates or questions.",
        placeholder:
          "Briefly explain any issues or glitches you encounter when using the Grayce App.  If possible, please upload screenshots to assist our support team in resolving the problem promptly.",
        title: "Issue submitted successfully"
      },
      suggested: "Suggested",
      termsOfUse: "Terms of use",
      timeGrantExplanations: {
        clientPurchase: "{fullName} purchased a package",
        employerPurchase: "Employer, {name}",
        graycePurchase: "Grayce, complementary",
        youPurchase: "You purchased a package"
      },
      timezone: "Timezone: {timezone}",
      twoFactor: {
        SMS: {
          description: "We'll send a code to the number you choose.",
          inputCode: "Input the verification code we sent to {number}",
          title: "Text message (SMS)"
        },
        TOTP: {
          description: "You'll get a login code from your authentication app",
          inputCode: "Input the verification code from your authentication app",
          instructions: "Instructions for setup",
          step1: "1. Download an authentication app",
          step1Description: "We recommend downloading Google Authenticator if you don't have one installed.",
          step2: "2. Scan this barcode/QR code or copy the key",
          step2Description:
            "Scan this barcode/QR code in the authentication app or copy the key and paste it in the authentication app.",
          step3: "3. Copy and enter 6-digit code",
          step3Description:
            "After the barcode/QR code is scanned or the key is entered, your authentication app will generate a 6-digit code. Copy the code and then come back to Grayce to enter it.",
          title: "Authentication app"
        },
        begin: "Set",
        delete: "Remove",
        description: "Enter a mobile phone you would like to use for SMS verification.",
        enabled: "Enabled",
        enterANumber: "Enter a mobile phone you would like to use for SMS verification.",
        enterCode: "Enter your verification code",
        howToGetCodes: "How you get login codes",
        noCode: "Didn't receive a code?",
        options: "Options",
        phoneNumber: "Phone number: {number}",
        reallyDelete: "Remove two factor authentication. We will not require a verification code.",
        receiveCall: "Receive a call",
        recommended: "Recommended",
        remove: "Remove two factor authentication",
        resendCode: "Resend code",
        secureAccount: "Secure your account with two factor authentication",
        sendCode: "Send code via SMS",
        setUpDescription:
          "When you sign in, you’ll use a code from your mobile device to confirm your identity and keep your account safe.",
        setUpLater: "Set up later",
        setUpTitle: "Set up two factor authentication",
        status: "Status: ",
        twoFactorAuthentication: "Two factor authentication",
        verify: "Verify",
        wait: "You have reached the limit of resends, try again later"
      },
      userAccount: "Your information",
      viewDetails: "View details",
      viewProfile: "View profile",
      yourPlan: "Your active packages"
    },
    carePlan: {
      title: "Plan"
    },
    careProfile: {
      age: "Age: {age}",
      careRecipient: "Name: {name}",
      careTeam: {
        addSomeone: "Add someone to your support team",
        cancel: "Withdraw invitation",
        copied: "Invitation link copied",
        copy: "Copy invitation link",
        description: "Share your Grayce benefit with others who help care for your loved one",
        edit: "Edit",
        editDetails: "Edit details",
        editMyDetails: "Edit my details",
        editMyRelationship: "Edit my relationship",
        editRelationship: "Edit relationship",
        pending: "Pending",
        reallyDelete: "Are you sure you want to remove {fullName} from this care journey?",
        remove: "Remove from account",
        resend: "Resend invitation",
        resentInvalidToast: "You must wait 5 minutes to resend your invitation",
        resentToast: "Your invitation has been resent",
        title: "Care team"
      },
      countryOfResidence: "Country of residence: {country}",
      placeOfResidence: "Place of residence: {place}",
      primaryLanguage: "Primary language: {language}",
      provider: {
        add: "Add providers",
        addProvider: "Add provider",
        blank: "Blank provider form",
        description: "Track your loved one's medical providers to stay organized and simplify information sharing.",
        download: "Download your provider list",
        information:
          "We'll ask about their primary care provider, other doctors, and other care providers.  Fill in the information you have. You can always make updates later.",
        noProvidersAccess: "No providers listed",
        notSeeingProviders: "Not seeing any providers",
        notStarted: "Provider tracker has not been started.",
        started: "Provider tracker was started but has not been completed."
      },
      sections: {
        about: "About",
        areasOfConcern: "Areas of concern",
        basic_info: "Basic info",
        careProfileMenu: "Care profile menu",
        careTeam: "Care team",
        client_info: "Member info",
        conclusion: "Conclusion",
        financial: "Financial",
        functional: "Functional",
        grayceSupport: "Grayce support",
        homeSafety: "Home safety",
        legal: "Legal",
        main: "Member info",
        medical: "Medical",
        medications: "Medications",
        next_consult: "Next session",
        notes: "Notes",
        providerContacts: "Provider contacts",
        social: "Social",
        stage: "Current stage",
        wellbeing: "Your well-being",
        whyGrayce: "Why Grayce"
      },
      title: "Profile"
    },
    consults: {
      bookAConsult: "Book a session",
      bookAConsultWith: "Book a session with {name}",
      canceled: "Canceled sessions",
      consultWithExpert:
        "Pick a time, and we’ll figure this out together. Sessions can be 30 or 60 minutes with a video option.",
      differentDay: "Pick a different day",
      differentTime: "Pick a different time",
      editTopicsModal: {
        title: "Edit discussion topics"
      },
      fromYourTimezone: "{difference} from your timezone",
      goBack: "Go back",
      hereForYou: "Need support? Have questions? I’m here for you.",
      joinMeetingInstructions: {
        part1: "If you would like to video conference with our Care Partners, please",
        part2: "download the Zoom app",
        part3: "If you prefer a phone call, dial in using the number and meeting ID in your session meeting."
      },
      noCanceledConsults: "No canceled sessions",
      noPastConsults: "No completed sessions",
      noUpcomingConsults: "No upcoming sessions",
      offersInterpretation: "Grayce offers interpretation services for sessions.",
      past: "Past sessions",
      remove: "Remove",
      requestConsultTemplate:
        "{firstName} could not find a time that worked on your calendar. Here are times that work best for {firstName}:",
      requestLanguageSupport: "Request language interpretation",
      rescheduleConsult: "Reschedule your session",
      rescheduleConsultWith: "Reschedule your session with {name}",
      returnToPrevious: "Return to previous question",
      scheduleConsult: "Schedule session",
      sendMessageWithRequest: "Send message with request",
      seperator: ": ",
      suggested: "Suggested next session",
      title: "Session",
      upcoming: "Upcoming session",
      updateConsult: "Update session"
    },
    documents: {
      reallyDelete: "Are you sure you want to delete {fileResourceTitle}?",
      title: "Documents"
    },
    gatherWaitlist: {
      cookiesNotAccepted:
        "In order to fill out this form, you must first consent to Grayce's use of functional cookies. You can",
      manageMyConsent: "click here to manage your consent."
    },
    header: {
      journey: "Journey"
    },
    home: {
      welcome: "Welcome, {firstName}",
      welcomeBack: "Welcome back, {firstName}"
    },
    inviteeRegistration: {
      instructions: "Please fill out the details below to accept your invitation to Grayce."
    },
    journeys: {
      actionItems: "{count, plural, =0 {No action items} one {# action item} other {# action items}}",
      completeOnboarding: "Incomplete profile - tell us more so we can help!",
      consult: "Next session: {date}",
      decline: "Decline",
      finishProfile: "Finish profile",
      join: "Join",
      messages: "{count, plural, =0 {No new messages} one {# Unread message} other {# Unread messages}}",
      myAccount: "My account ({package} remaining)",
      noConsult: "No session scheduled",
      sharedWithMe: "Shared with me",
      title: "Journeys",
      welcomeBack: "Welcome back, {firstName}!"
    },
    legalDocuments: {
      begin: "Begin legal documents tracker",
      continue: "Continue legal documents tracker",
      editLegalDocument: "Edit legal document",
      getStarted: "Get started",
      introParagraph1:
        "Track and store your loved one's important legal documents to stay organized and simplify information sharing.",
      introParagraph2:
        "We'll ask about important legal documents and where they're stored. It isn't necessary to have the documents with you, but it can make it easier. Fill in the information you have. You can always make updates later.",
      messages: {
        dpoa_finances:
          "A durable power of attorney (DPOA) for finances allows another person to act on your behalf in financial matters.",
        dpoa_health_care:
          "A durable power of attorney for health care allows another person to make medical decisions for you. In some states, the living will and durable power of attorney for health care are combined into one document called an advance health care directive.",
        guardian_trust:
          "A trust allows a person to transfer an estate, including money, real estate, and other assets both during and after their life.",
        guardian_will:
          "A will distributes a person's assets after their death, as well as designates guardians for minor children.",
        living_will:
          "A living will lets you state your choices for end-of-life medical treatments. In some states, the living will and durable power of attorney for health care are combined into one document called an advance health care directive.",
        trust:
          "A trust allows a person to transfer an estate, including money, real estate, and other assets both during and after their life.",
        will: "A will distributes a person's assets after their death, as well as designates guardians for minor children."
      },
      reallyDelete: "Are you sure you want to delete {legalDocumentKind}?",
      review: {
        expertReview: "If you haven’t already, {scheduleConsult} with your Care Partner or {sendMessage}.",
        scheduleConsult: "schedule a session",
        scheduleConsultButton: "Schedule a session",
        sendMessage: "send a message",
        thankYou: "Nice work! You’re all done. Remember you can always come back to reference this or make changes."
      },
      sections: {
        header: "Important legal documents"
      },
      trackAndStore:
        "Track and store your loved one’s important legal documents to stay organized and simplify information sharing.",
      whatIsAsked:
        " We’ll ask about important legal documents and where they’re stored. It isn’t necessary to have the documents with you, but it can make it easier. Fill in the information you have. You can always make updates later."
    },
    library: {
      title: "Library"
    },
    login: {
      loginAccept: "Login and join journey",
      noAccount: "Don't have an account?",
      signUp: "Sign up",
      toContinueTo: "To continue to {redirectUri}"
    },
    marketplace: {
      cost: "Cost",
      email: "Email",
      employerBenefits: "Employer benefits",
      employerSponsored: "Employer-sponsored",
      goToSite: "Go to site",
      hoursOfOperation: "Hours of operation",
      lastUpdated: "Last updated: {date}",
      marketplace: "Benefits hub",
      notAvailable: "This content is only available to the primary account holder.",
      pdfs: "PDFs",
      phone: "Phone",
      scanning: "Scanning",
      viewAllDetails: "View all details",
      website: "Website"
    },
    medications: {
      begin: "Add medications",
      blankForm: "Download or print this form, {link}.",
      blankLink: "here",
      continue: "Add medications",
      description:
        "Track your loved one's medications to stay organized, ensure safety, and simplify information sharing.",
      inProgressDescription: "You're almost there! Finish adding your loved one's medications.",
      lastUpdated: "Last updated {date}",
      list: {
        addMedication: "Add medication",
        addMedications: "Add medications",
        all: "All ({count})",
        blank: "Blank medication form",
        details: "Details",
        dosageAndFrequency: "Dose/frequency",
        download: "Download your medication list",
        format: "Form",
        formatOptions: {
          injection: "Injection",
          oral: "Oral",
          other: "Other",
          suppository: "Suppository",
          topical: "Topical"
        },
        indication: "Indication",
        instructions: "Instructions",
        kind: "Type",
        kindOptions: {
          over_the_counter: "Over-the-counter",
          prescription: "Prescription",
          vitamin_or_supplement: "Vitamin or supplement"
        },
        noMedicationsListed: "No medications listed",
        notTakingMedications: "Not taking any medications",
        notes: "Additional notes",
        oTC: "OTC",
        overTheCounter: "Over-the-counter ({count})",
        paymentType: "Payment type",
        paymentTypeOptions: {
          covered_by_insurance: "Covered by insurance",
          self_pay: "Not covered. Self pay.",
          unknown: "Unknown"
        },
        pharmacy: "Pharmacy",
        prescriber: "Prescribing clinician",
        prescriptionNumber: "Rx#",
        prescriptions: "Prescriptions ({count})",
        refillFrequency: "Refill frequency",
        vAndS: "V&S",
        vitaminsAndSupplements: "Vitamins & supplements ({count})"
      },
      notStarted: "Medication tracker has not been started",
      over_the_counter: {
        addMedication: "Add over-the-counter medications",
        description:
          "Next, let’s add over-the-counter medications like Aspirin, Benadryl, Zyrtec, and any other medication that your loved one takes regularly.",
        title: "Step 2: Over-the-counter medications",
        toggleOne: "My loved one does not take any over-the-counter medications.",
        toggleTwo: "I don't have access to this information right now."
      },
      position: "Section {count} of 5",
      prescriptions: {
        addMedication: "Add prescription medications",
        description:
          "First, let’s add prescription medications. These include any medications that are prescribed by a doctor. If you have the medications nearby, you can take a look at the labels for current details. Keeping this list up to date will be useful during doctors visits. Be sure to revisit this list as things change.",
        title: "Step 1: Prescription Medications",
        toggleOne: "My loved one does not take any prescription medications.",
        toggleTwo: "I don't have access to this information right now."
      },
      progress:
        "Your medication tracker is in progress, but you have a little more to do. You are on section {count} of 5",
      reallyDelete: "Are you sure you want to delete {medicationName}?",
      review: {
        close: "Close",
        expert: "Grayce Care Partner: {firstName}",
        expertReview:
          "Your Care Partner will review the medications and provide personalized resources. If you haven’t already, book a session with your Care Partner {here} or {sendMessage}.",
        here: "here",
        remember: "Remember you can always come back to reference this or make changes.",
        sendMessage: "send a message",
        thankYou: "Nice work! You’re all done."
      },
      sections: {
        capturing:
          "We will be capturing prescription medications, over the counter medications, and vitamins and supplements.",
        getStarted: "Get started",
        header: "Medications",
        instructionalContent:
          "We'll ask about prescription and over-the-counter medications, vitamins and supplements.  Fill in the information you have. You can always make updates later.",
        position: "Section {count} of 5",
        whyItMatters:
          "Track your loved one's medications to stay organized, ensure safety, and simplify information sharing."
      },
      started: "Medication tracker was started {date}, but has not been completed.",
      title: "Medications",
      vitamins_and_supplements: {
        addMedication: "Add vitamins and supplements",
        description: "Finally, let’s add vitamins and supplements, like calcium or multi-vitamins.",
        title: "Step 3: Vitamins and Supplements",
        toggleOne: "My loved one does not take any vitamins or supplements.",
        toggleTwo: "I don't have access to this information right now."
      }
    },
    messages: {
      title: "Messages"
    },
    packagePurchaseOptionPurchase: {
      billingInfoLabel: "Billing information",
      cookiesNotAccepted:
        "In order to make payments in Grayce, you must consent to payment-related cookies. You can manage your cookies by clicking on the button below.",
      hourLabel: "{count, plural, one {# hour} other {# hours}}",
      manageCookies: "manage my cookies",
      manageMyConsent: "Manage my consent",
      stripeFailure:
        "Sorry, we're having trouble connecting to our payments provider. Try reloading the page or checking that your internet connection is working correctly.",
      title: "Extend your Grayce services"
    },
    personalizeExperience: {
      back: "Back",
      closingPage: {
        here: "here",
        niceWork: "Nice work! You're all done.",
        questions: "If you have any questions {sendMessage}.",
        sendMessage: "send a message"
      },
      finishLater: "Finish later",
      next: "Next",
      other: "Other",
      section: "Section {count} of {total}",
      title: "Personalize my experience"
    },
    providers: {
      lastUpdated: "Last updated {date}",
      list: {
        addProviders: "Add providers",
        address: "Address",
        details: "Details",
        fax: "Fax",
        kind: "Type",
        kindOptions: {
          other_care_provider: "Other care provider",
          other_doctor: "Other doctor",
          primary_care_provider: "Primary care provider"
        },
        notes: "Notes",
        other_care_provider: "Other care provider",
        other_doctor: "Other doctor",
        patientPortal: "Patient portal",
        phone: "Phone",
        practiceName: "Name of practice",
        primary_care_provider: "Primary care provider",
        specialty: "Specialty",
        website: "Website"
      },
      other_care_providers: {
        addProvider: "Add other care provider",
        description: "Finally, let’s add any other care providers, like a dentist or optometrist.",
        title: "Step 3: Other care providers",
        toggleOne: "My loved one does not have any other care providers.",
        toggleTwo: "I don't have access to this information right now."
      },
      other_doctors: {
        addProvider: "Add other doctor",
        description:
          "Next, let’s add any other doctors, like a heart doctor or a kidney doctor. To stay organized, add all other doctors even if they only see them once or twice a year.",
        title: "Step 2: Other doctors",
        toggleOne: "My loved one does not see any additional doctors.",
        toggleTwo: "I don't have access to this information right now."
      },
      primary_care_provider: {
        addProvider: "Add primary care provider",
        description:
          "First, let’s add the primary care provider. They may also be known as a general practitioner (GP). The primary care provider may be a medical doctor (MD), nurse practitioner (NP), or physician assistant (PA) who is the main contact for regular check-ups, prevention, and general issues. Be sure to revisit this if things change.",
        title: "Step 1: Primary care provider",
        toggleOne: "My loved one does not have a Primary Care Provider.",
        toggleTwo: "I don't have access to this information right now."
      },
      reallyDelete: "Are you sure you want to delete {providerName}?",
      review: {
        close: "Close",
        expert: "Grayce Care Partner: {firstName}",
        expertReview:
          "Your Care Partner will review the providers and provide personalized resources. If you haven’t already, book a session with your Care Partner {here} or {sendMessage}.",
        here: "here",
        remember: "Remember you can always come back to reference this list or make changes later.",
        sendMessage: "send a message",
        thankYou: "Nice work! You’re all done."
      },
      sections: {
        getStarted: "Get started",
        header: "Provider contacts",
        instructionalContent:
          "We'll ask about their primary care provider, other doctors, and other care providers.  Fill in the information you have. You can always make updates later.",
        trackProviders: "Track your loved one's medical providers to stay organized and simplify information sharing."
      }
    },
    registration: {
      alreadyHaveAccount: "Already have an account? {signIn}",
      employerSponsoredPrompt: "Activating your employer-sponsored benefit?",
      h1: "Because you care.",
      instructions: "Please fill out the details below to help us match you with a Grayce Care Partner.",
      inviteWarning: {
        body: "Looks like this email address is already linked to a pending invitation from a Grayce member. Were you trying to accept that invitation instead of creating a new account? If so, please check {email} for an email titled 'You have been invited to Grayce' and follow the link inside to proceed.",
        proceedAnyways: "Make a new account instead",
        resendEmail: "Resend invitation email",
        title: "Invitation awaiting your response",
        welcome: "Welcome to Grayce!"
      },
      number: {
        one: "1",
        three: "3",
        two: "2"
      },
      signIn: "Sign in",
      sittercityMessage:
        "You're signing up for a paid Annual Access Membership & Care Plan as part of our partnership with Sittercity.",
      steps: {
        details: {
          EMPLOYER_SPONSORED: "Verify benefits",
          PAID: "Complete your profile"
        },
        payment: "Payment",
        start: "Create account"
      },
      title: "Empowering you when caring for loved ones. Get real-life support in under two minutes.",
      weRecommend:
        "We recommend signing up with your personal email address so that you can continue to access Grayce if your employment changes."
    },
    sittercityPrompt: "Visiting from Sittercity?"
  },
  community: {
    join: "Join the community",
    leaveReaction: "Leave a reaction",
    longTitle: "Gather, a community by Grayce",
    reactions: {
      faceJoy: "Face with tears of joy",
      faceSad: "Face crying",
      heartRed: "Red heart",
      oneHundred: "Hundred points symbol",
      partyPopper: "Party popper",
      prayerHands: "Hands pressed together",
      raisedHands: "Hands raised in celebration",
      thumbsUp: "Thumbs up"
    },
    shortTitle: "Gather",
    subtitle:
      "Gather is an online community by Grayce where you can attend events, learn from others, and find peer support",
    title: "You belong here"
  },
  confirmEmail: {
    failed: "Sorry, that link didn't work. Try resending the verification email by clicking on the button below.",
    header: "Confirm your email"
  },
  consentFormUploader: {
    attachmentLabel: "Select consent form file",
    documentForLabel: "Who is this consent form for?",
    title: "Upload consent form"
  },
  consentFormWizard: {
    email: {
      documentForLabel: "Who is this consent form for?",
      recipientEmailAddressLabel: "Signer's email address",
      recipientNameLabel: "Name of person who will sign the consent form"
    },
    embed: {
      documentForLabel: "Who is this consent form for?",
      instructions: "By signing, you confirm that you have the legal authority to act on behalf of the person named."
    },
    explanation1: "This document must be signed by the person named or their legal representative.",
    explanation2: "Who is signing?",
    explanation3: "Need someone else to sign?",
    medium: {
      embed: {
        EMBED_PARENT: "I'm signing for my child who is a minor",
        EMBED_POWER_OF_ATTORNEY:
          "I have power of attorney for my loved one and am signing on their behalf. (A power of attorney legally allows someone to act on a loved one’s behalf.)",
        EMBED_YOURSELF: "I am signing for myself"
      },
      other: {
        EMAIL: "Email for signature",
        UPLOAD: "Download for signature (You’ll need to fill out and upload later)"
      }
    },
    sendEmail: "Send email",
    title: "Sign consent form",
    upload: {
      filename: "GrayceConsentForm.pdf",
      instructions:
        "Download the form for the person named or their legal representative, once completed upload the consent form.",
      pdfName: "GrayceConsentForm.pdf"
    }
  },
  consultReport: {
    title: "Session report"
  },
  consults: {
    bookConsult: "Book session",
    bookWith: "Book with {name}",
    booked: {
      bookAnother: "Book another session",
      return: "Return to home",
      scheduled: "Scheduled!",
      yourBooked: "You're booked!"
    },
    cancel: {
      cancelYourConsult: "Cancel your session with {name}",
      reschedule: "reschedule",
      rescheduleConsult: "Do you actually need to {reschedule} your session?",
      title: "Cancel session"
    },
    confirm: {
      attendees: "Attendees",
      bookSession: "Book session",
      byPhone: "By phone",
      contactMethod: "Contact method",
      dateAndTime: "Date and time",
      discuss: "What do you want to discuss?",
      editPhoneNumber: "Set a phone number for this session",
      expert: "Grayce Care Partner",
      inviteOthers: "Invite others",
      optional: "Optional",
      regardingCare: "Regarding care of {name}",
      sessionLength: "Session length",
      sessionPhoneNumber: "Session phone number",
      smsReminders: "SMS reminders",
      title: "Confirm details",
      zoom: "Zoom"
    },
    consultWith: "Session with {expertName}",
    contactMethod: {
      PHONE: "Phone",
      ZOOM: "Zoom",
      description: {
        PHONE: "We will call you at the number you provide.",
        ZOOM: "We’ll provide a zoom link 10 minutes before your session on this page."
      },
      phone: "Phone",
      review: "Review and confirm",
      title: "Contact method",
      zoom: "Zoom"
    },
    duration: {
      availbleSessionLengths:
        "If you do not see a session length that meets your needs, you may need to purchase additional time.",
      infoModal: {
        closeButton: "Back to scheduling",
        sixtyMin: {
          carePlan: "Creating a Care Plan",
          complexIssues: "Addressing complex issues or caring for multiple loved ones",
          header: "Select 60 minutes for:",
          navigatingHealthcare: "Guidance navigating healthcare systems or resources",
          supportingYou: "Supporting you as you help others"
        },
        thirtyMin: {
          checkins: "Quick check-ins or updates",
          header: "Select 30 minutes for:",
          specifics: "Answering specific questions or concerns",
          tracking: "Tracking progress from previous sessions"
        }
      },
      minutes: "Minutes",
      notSure: "Not sure?",
      selectDateAndTime: "Select date and time",
      sessionLength: "Session length"
    },
    loadMore: "Load more",
    noSessionHistory: "No completed or scheduled sessions",
    previousDays: "Previous days",
    regarding: "Regarding care of {careProfileName}",
    reschedule: {
      afternoon: "Afternoon",
      bookWithAny: "Book with any available Care Partner",
      cancel: "Cancel this session",
      cantFind: "Cant find a time that works? {link}",
      days: {
        0: "M",
        1: "T",
        2: "W",
        3: "T",
        4: "F",
        5: "S",
        6: "S"
      },
      duration: "Length",
      meetingScheduled: "This meeting is scheduled",
      min30: "30 mins",
      min60: "60 mins",
      morning: "Morning",
      reschedule: "Reschedule",
      selectNewDateAndTime: "Select a new date and time",
      updated: {
        cancel: "Cancel session",
        creatingMeeting: "Creating meeting",
        inviteOthers: "Invite others",
        makeChanges: "Want to make changes? {reschedule} or {cancel}",
        reschedule: "Reschedule",
        what: "What",
        when: "When",
        where: "Where",
        who: "Who",
        withExpert: "Care Partner session with {name}"
      },
      weEmailed: "We emailed you and all other participants an invitation with all the details."
    },
    scheduleAConsultWith: "Schedule a session with {name}",
    scheduleSession: "Schedule session",
    selectTime: {
      afternoon: "Afternoon",
      anyAvailable: "Any available",
      cantFind: "Can't find a time that works? {link}",
      contactMethod: "Contact method",
      grayceExpert: "Grayce Care Partner",
      morning: "Morning",
      searchAnyAvailable: "Search any available Care Partner",
      selectTime: "Select date and time"
    },
    selectTopics: "What do you want to discuss? Select all topics that apply.",
    smsReminders: "Do you want SMS reminders?",
    smsRemindersDescription: "Your phone number will only be used for transactional confirmation messages.",
    smsRemindersLabel: "Your phone number",
    topics: {
      sections: {
        guide: "Simplify and guide",
        research: "Research and recommend",
        saveTime: "Save time"
      }
    }
  },
  cookieBanner: {
    acceptAll: "Accept all",
    acceptNecessary: "Acccept necessaary",
    description:
      'We use cookies to personalize our website and offerings to your interests and for measurement and analytics purposes. We do <strong style="font-weight: 600;">not</strong> use cookies for advertising purposes, and we will <strong style="font-weight: 600;">not</strong> share your data with data brokers. By using our website and our products, you agree to our use of cookies. <button data-cc="show-preferencesModal" type="button" class="cc__link" style="margin-top: 15px;">Let me choose.</button>',
    domain: "Domain",
    name: "Name",
    origin: "Origin",
    preferences: {
      analytics: {
        description:
          "Analytics cookies help us understand how visitors interact with our Services, allowing us to analyze and improve our Services (also through third party analytics).",
        title: "Analytics"
      },
      close: "Close",
      functionality: {
        description:
          "Functionality cookies are used to keep our site and services working correctly, like showing you the right information for your selected location or remembering your preferences.",
        title: "Functionality"
      },
      intro: {
        description:
          'Grayce uses cookies to ensure the basic functionality of our website and to enhance your online experience. For each of the following categories, you can choose to opt-in or out whenever you want. For further details about cookies and other sensitive data, please read the full <a href="https://withgrayce.com/privacy-policy/" class="cc__link">privacy</a> and <a href="/cookie-policy/" class="cc__link">cookie</a> policies.',
        title: "Cookie usage"
      },
      moreInfo: {
        description:
          'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:support@withgrayce.com">contact us</a>.',
        title: "More information"
      },
      necessary: {
        description: "These cookies are necessary for the functioning of our service",
        title: "Necessary"
      },
      payment: {
        description:
          "These cookies help prevent fraud in payment processing and are required when making payments to Grayce",
        title: "Payment fraud protection cookies"
      },
      save: "Save settings",
      title: "Cookie preferences"
    },
    title: "Grayce uses cookies"
  },
  cookiePolicy: {
    headings: {
      control: "How can I control the cookie preferences?",
      howDoWeUseCookies: "How do we use cookies?",
      title: "Cookie Policy",
      types: {
        analytics: "Analytics cookies",
        functional: "Functional cookies",
        payment: "Payment cookies",
        required: "Required cookies",
        title: "What types of cookies do we use?"
      },
      whatAreCookies: "What are cookies?"
    },
    paragraphs: {
      control: {
        blockEffects:
          "Please note, however, that by blocking or deleting cookies and similar technologies used on our websites, You may not be able to take full advantage of the websites.",
        manageIndividualCookies:
          "You may opt-out from the collection of non-essential device and usage data on our site by managing your cookies at the individual browser level. {manageIndividualCookiesLink} for ways to erase cookies from your computer and to prevent cookies from being created on your browser.",
        useBrowserSettings:
          "If you prefer, you can usually choose to set your browser to remove cookies and reject cookies. If you configure your browser to prevent Grayce from collecting cookies, You will not be able to login to Grayce services. Such action could also affect certain features or services of our Website.",
        useConsentPopup:
          "You can manage your cookies preferences by making changes in the consent popup, enabling or disabling the cookie categories according to your preferences.",
        useDoNotTrack:
          "Separately, your browser may offer you a “Do Not Track” option, which allows you to signal to operators of websites and web applications and services that you do not wish such operators to track certain of your online activities over time and across different websites. Our Services do not support Do Not Track requests at this time. To find out more about “Do Not Track,” you can visit www.allaboutdnt.com.",
        useManageConsentLink:
          "Should you decide to change your preferences later through your browsing session, you can click on the “manage my consent” link above. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely."
      },
      explainer:
        "This Cookie Policy explains what cookies are and how we use them. You should read this policy to understand what cookies are, how we use them, the types of cookies we use (i.e, the information we collect using cookies and how that information is used) and how to control the cookie preferences. For further information on how we use, store and keep your personal data secure, see our {explainerLink}.",
      explainerLink: "Privacy Policy",
      howDoWeUseCookies:
        "We use cookies and other technologies to, among other things, better serve you with more tailored information, and to facilitate efficient and secure access to the Website. Essential cookies are those necessary for us to provide services to you. All other cookies are non-essential. We use two types of non-essential cookies: (1) cookies used to analyze your behavior on a website (“Analytics Cookies”); and (2) cookies used to provide you enhanced functionality (“Functional cookies”). We have provided, below, a full list of our cookies, categorized as described above. We have described the purpose of each, whether they are Grayce or Third Party cookies, and how to withdraw consent to their use. We have also indicated which cookies are “session cookies” (which last for as long as you keep your browser open) and “persistent cookies” (which remain on your hard drive until you delete them or they expire).",
      learnMore:
        "Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.",
      manageConsent: "You can at any time change or withdraw your consent: {manageConsentLink}.",
      manageConsentLink: "manage my consent",
      moreAboutHowWeUseCookies:
        "We may also collect information using pixel tags, web beacons, clear GIFs or other similar technologies. These may be used in connection with some Site pages and HTML formatted email messages to, among other things, track the actions of Site users and email recipients, and compile statistics about Site usage and response rates.",
      types: "The cookies used on our website are grouped into the following categories:",
      whatAreCookies:
        "Cookies are small files that a web server sends to your computer or device when you visit a web site that uses cookies to keep track of your activity on that site. They hold a small amount of data specific to that web site, which can later be used to help remember information you enter into the site (like your email or other contact info), preferences selected, and movement within the site. If you return to the previously visited web site (and your browser has cookies enabled), the web browser sends the small file to the web server, which tells it what activity you engaged in the last time you used the site, and the server can use the cookie to do things like expedite logging in and retrieving user data and keeping your browser session secure."
    }
  },
  documents: {
    consentForm: "Consent form",
    consentFormDescription:
      "In order for your Care Partner to provide the highest level of concierge support and guidance, Grayce requests signed authorization to exchange information with third-party providers.",
    createFolder: "Create a folder",
    documentsDescription:
      "Upload files like legal and medical documents to stay organized. Invite others to your Grayce account to securely share documents, too.",
    editFileResource: "Edit file",
    editFolder: "Edit folder",
    fileResourceReallyDelete: "Are you sure you want to delete this file?",
    folderReallyDelete: "Are you sure you want to delete this folder?",
    folders: "Folders",
    hasBeenAdded: "has been added to your files",
    interactiveToolkits: "Shared worksheets",
    kinds: {
      interactive_document: "Shared worksheets",
      other: "Other documents"
    },
    mustHaveZeroFiles: "Move all the files outside of this folder to delete it",
    myFiles: "My files",
    noDocumentsFound: "No documents found",
    noFiles: "There are no files here",
    recentDocuments: "Recent documents",
    saveWorksheet: "Save worksheet",
    signConsentForm: "Sign consent form",
    tableHeadings: {
      actions: "Actions",
      added: "Added",
      documents: "Documents",
      lastModified: "Last modified",
      name: "Name",
      owner: "Owner"
    },
    tools: "Tools",
    uploadConsentForm: "Upload consent form",
    uploadFile: "Upload file",
    viewDocuments: "View {count, plural, one {# Document} other {# Documents}}",
    worksheets: {
      add: "Add worksheet",
      autoSaves:
        "Your changes are saved automatically. When you’re finished check the box below to notify {expertFirstName}",
      cannotUpdateDuplicateGoal:
        "Your Care Partner is updating information in this goal, you can update this once they are done.",
      discardChanges: "Discard changes",
      doThisLater: "Not now",
      edit: "Edit worksheet",
      leaveWithoutSaving: "Are you sure you want to leave without saving your work?",
      openDocuments: "View in files",
      recommended: "Worksheets recommended for you",
      recommendedDescription1: "Worksheets are dynamic checklists to help you save time and mental energy.",
      recommendedDescription2:
        "Not every worksheet will apply to you, but when you find one that does, you can personalize it, save it to your files, and even fill it out more than once.",
      saveToMyFiles: "Save to drive",
      saveWorksheet: "Save worksheet",
      savedToDocuments: "Worksheet saved to your files!",
      saving: "Saving",
      tableHeadings: {
        focusArea: "Focus area(s)",
        title: "Title"
      },
      title: "Worksheet",
      viewAll: "View all interactive worksheets",
      wantToSaveToFiles: "Save this worksheet to your files?",
      youCanAlwaysDoThisLater: "You can always do this later."
    }
  },
  drive: {
    consentForm: "Consent form",
    copyLink: "Copy link",
    createFolder: "Create folder",
    delete: "Delete",
    displayLess: "Display less",
    displayMore: "Display more",
    documents: "Documents",
    download: "Download",
    files: "Files",
    folders: "Folders",
    hasFiles: "Move all the files outside of this folder to delete it",
    move: "Move",
    myFiles: "My files",
    previewUnavailable:
      "This file type is not supported for preview. Download and open the file on your computer or phone to view it.",
    recentFiles: "Recent files",
    rename: "Rename",
    return: "Return to Drive",
    searchDrive: "Search drive....",
    tableHeaders: {
      from: "From",
      name: "Name",
      updated_at: "Last activity"
    },
    uploadDocument: "Upload document",
    worksheet: "Worksheet",
    worksheets: "Worksheets"
  },
  expert: {
    account: {
      title: "Account"
    },
    activity: {
      activity: "Activity",
      allActivity: "All activity",
      dashboard: "Dashboard",
      days: {
        today: "Today, {date}",
        weekday: "{day}, {date}",
        yesterday: "Yesterday, {date}"
      },
      daysAndTime: {
        hoursAgo: "{hours, plural, one {# hour} other {# hours}} ago ({time})",
        minutesAgo: "{minutes, plural, one {# minute} other {# minutes}} ago ({time})",
        today: "Today {time}",
        yesterday: "Yesterday {time}"
      }
    },
    ai: {
      ai: "AI",
      ask: "Ask question",
      messageInputLabel: "Message",
      messagePlaceholder: "How can I help you?",
      newTopic: "New topic",
      yourTopics: "Your topics"
    },
    allTags: {
      title: "All tags"
    },
    automation: {
      title: "Automation"
    },
    automationApplication: {
      title: "Automation application"
    },
    automations: {
      addAutomation: "Add automation",
      title: "Automations"
    },
    banners: {
      joinJourney: "Join journey",
      leaveJourney: "Leave journey",
      viewingAClientJoined: "You are viewing a member assigned to {expertName}. You have joined this journey.",
      viewingAClientNotJoined: "You are viewing a member assigned to {expertName}."
    },
    carePlans: {
      tableHeadings: {
        automated: "Automated",
        care_plan: "Care Plan",
        care_plan_due_date: "Due date",
        care_plan_last_updated: "Last updated",
        care_plan_turn_around_time: "Turnaround",
        client_name: "Member",
        employer_name: "Employer",
        loved_one_name: "Loved one",
        primary_expert: "Care Partner",
        status: "Status"
      },
      viewCarePlan: "View Care Plan",
      viewPublished: "View published Care Plan"
    },
    clients: {
      tableHeadings: {
        country: "Country",
        created_at: "Created at",
        employer: "Employer",
        lovedOnes: "Loved one(s)",
        name: "Name",
        timezone: "Timezone"
      }
    },
    community: {
      title: "Community"
    },
    consults: {
      consultCount: "{count, plural, one {Showing # session} other {Showing # total sessions}}",
      consults: "Sessions",
      consultsExplained:
        "This includes all the sessions on your primary journeys as well as any session you are assigned to on other journeys",
      dashboard: "Dashboard",
      days: {
        today: "Today",
        tomorrow: "Tomorrow"
      },
      tableHeadings: {
        client_name: "Member",
        date: "Date",
        finalized: "Finalized",
        language_interpretation: "Language interpretation",
        loved_one_name: "Loved one",
        role: "Care Partner",
        time: "Time"
      }
    },
    dashboard: {
      addTask: "Add task",
      boxHeadings: {
        clientsPendingReview: "Members pending review",
        expertHighlights: "Care Partner highlights",
        expertSummaries: "Care Partner summaries",
        keyActivity: "Key activity (last 3 days)",
        newMessages: "New messages ({count})",
        surveyResponses: "Survey responses",
        taskList: "Task list",
        upcomingConsults: "Upcoming sessions"
      },
      careTeam: "Care team",
      consults: {
        dashboard: "Dashboard",
        duration: {
          1: "30 minute sessions",
          2: "60 minute sessions",
          title: "Sessions by length"
        },
        loadMore: "Load more",
        noMatch: "The difference between the session time logged and the actual session time is {count}",
        position: {
          0: "1st session",
          1: "2nd session",
          2: "3rd+ session",
          title: "Sessions by sequence"
        },
        sixtyMinutes: "60 minute sessions",
        thirtyMinutes: "30 minute sessions",
        totalTime: "Total time:"
      },
      cptat: {
        average: "Average:",
        title: "Care Plan turnaround time"
      },
      days: {
        today: "Today ({count})",
        tomorrow: "Tomorrow ({count})",
        weekday: "{day} ({count})"
      },
      expertHighlights: "Care Partner highlights",
      expertMetrics: "Care Partner metrics",
      hours: "{value} hours",
      icons: {
        commentEditIcon: "Go to chat messages"
      },
      kpi: "KPIs",
      lovedOne: "Loved one: {name}",
      markAsRead: "Mark as read",
      newClients: {
        noNewClients: "No new members",
        tableHeadings: {
          client: "Member",
          employer: "Employer",
          joined: "Joined",
          lovedOne: "Loved one"
        },
        viewedThemAll: "You've viewed them all!"
      },
      noUnreadMessages: "No new messages",
      noUpcomingConsults: "No upcoming sessions",
      overview: {
        active: "{count} active",
        averageHours: "Average hours used",
        averageHoursTip:
          "Minutes logged per member for any member that has time logged including billable and non-billable time",
        averageRefreshTime: "The time it takes for the Care Plan to be updated after being published",
        avgTimeToRefresh: "Avg time to refresh Care Plan",
        basedOnResponses: "Based on {count, plural, one {# review} other {# reviews}}",
        billable: "Billable",
        bookedConsultsCount: "Sessions by sequence",
        bookedConsultsTip: "Completed sessions based on sequence in a journey",
        carePlanTurnAroundTime: "Care Plan turnaround time",
        carePlanTurnAroundTimeDescription: "Time from the initial session report published to Care Plan published",
        clients: "Members",
        consults: "Sessions",
        consultsCount: "Sessions by length",
        consultsTip: "Completed sessions by length",
        consultsToday: "{count, plural, one {# session today} other {# sessions today}}",
        days: "days",
        engagement: "Engagement",
        hours: "hours",
        hrs: "{count, plural, one {# hr} other {# hrs}}",
        initialConsult: "1st sessions",
        journeys: "Journeys",
        nHours: "Hours {count}",
        newClients: "{count, plural, one {# new member} other {# new members}}",
        newClientsText: "New members",
        newMessages: "{count, plural, one {# new message} other {# new messages}}",
        newSignUps: "New sign ups",
        noConsultsToday: "No consults today",
        noMessages: "No new messages",
        noNewClients: "No new members",
        nonBillable: "Non-billable",
        oneHour: "60 mins",
        overdue: "{count} overdue",
        preConsult: "{count} pre-session",
        restConsult: "3rd+ sessions",
        secondConsult: "2nd sessions",
        surveyRatings: "Survey ratings",
        thirtyMinutes: "30 mins",
        today: "{count} today",
        totalClients: "{count, plural, one {# total member} other {# total members}}",
        totalTasks: "{count, plural, one {# total task} other {# total tasks}}",
        upcoming: "{count} upcoming",
        viewDetails: "View details"
      },
      ownedBy: "Owned by {name}",
      replyTo: "Reply to {name}",
      seeYourTasks: "See your tasks",
      signUps: {
        title: "New sign ups",
        total: "Total sign ups:"
      },
      taskFilters: {
        completedToday: "Completed today ({count})",
        today: "Today ({count})",
        tomorrow: "Tomorrow ({count})"
      },
      timeLogActivityKinds: {
        carePlan: "Care Plan",
        carePlanLite: "CPL",
        carePlanUpdates: "Care Plan update",
        clientMessaging: "Messaging",
        concierge: "Concierge",
        consult: "Sessions",
        documentation: "Documentation",
        messaging: "Messaging",
        other: "Other",
        resource: "Resources",
        resources: "Resources"
      },
      timeLogs: {
        0: "Care Plan",
        1: "Care Plan updates",
        2: "Resources",
        3: "Sessions",
        4: "Documentation",
        5: "Concierge",
        6: "Messaging",
        7: "Other",
        billable: {
          title: "Billable time"
        },
        dashboard: "Dashboard",
        loadMore: "Load more",
        non_billable: { title: "Non-billable time" },
        total: "Total:"
      },
      viewAllActivity: "View all activity",
      viewAllClients: "View all members",
      viewAllConsults: "View all sessions",
      viewAllTasks: "View all tasks",
      welcomeBack: "Welcome back, {firstName}",
      youReadThemAll: "You've read them all!",
      yourDashboard: "Your dashboard"
    },
    employers: {
      title: "Employers"
    },
    goalTemplate: {
      title: "Goal"
    },
    goalTemplates: {
      addGoal: "Set goal",
      archive:
        'Are you sure you want to archive "{title}"? This will remove it from the goal options. This can be undone.',
      editGoal: "Edit goal",
      global: "global",
      reallyArchive: "Really archive?",
      reallyUnarchive: "Really unarchive?",
      tagsLabel: "Tags",
      title: "Goals",
      unarchive:
        'Are you sure you want to unarchive "{title}"? This will add it to the goal options. This can be undone.'
    },
    highlights: {
      active: "Active: {count}",
      billable: "Billable: {value}%",
      nonBillable: "Non-billable: {value}%",
      preConsult: "Pre-session: {count}",
      stars: "{value} stars",
      tableHeadings: {
        avgRefreshTime: "Avg time to refresh Care Plan",
        consults_today: "Sessions today ({count})",
        initialConsult: "Initial sessions ({count})",
        name: "Care Partner",
        newClients: "New members ({count})",
        outOfOffice: "Out of office",
        restConsult: "3rd+ sessions ({count})",
        secondConsult: "2nd sessions ({count})",
        surveyRatings: "Survey ratings",
        timeTracking: "Time tracking",
        turnAroundTime: "Care Plan turnaround time",
        unread_messages: "Unread messages ({count})"
      }
    },
    internalNoteTemplate: {
      title: "Internal note template"
    },
    internalNoteTemplates: {
      newInternalNoteTemplate: "New internal note template",
      title: "Internal note templates"
    },
    journeys: {
      allJourneyList: "All journeys",
      andOthers: "{count, plural, one {and # other} other {and # others}}",
      archive: {
        businessDays:
          "** Business days are defined as Grayce working days: Monday - Friday. Automated tasks will not be scheduled on {companyHolidays}",
        companyHolidays: "company holidays",
        description:
          "Archiving takes place on the journey level in order to support clients who have multiple care journeys.",
        heading1: "Who can archive a journey?",
        heading1Step1: "Care Partner Managers",
        heading1Step2: "Primary Care Partner",
        heading1Step3:
          "Automatically by the app when a journey does not have Care Partner access. This occurs when the member has no unexpired hours and/or they do not have an active care planning package associated with the journey.",
        heading1Step3Sub: "This will happen {immediately} once the client no longer has Care Partner access",
        heading2: "What happens when a journey is archived?",
        heading2SubHeading1: "For Care Partners:",
        heading2SubHeading1Step1:
          "Journeys are removed from the Care Partner's list of active and engaged clients and moved to their archived list",
        heading2SubHeading1Step2:
          "When the journey loses Care Partner access, its tasks are removed from the Care Partner's to do list.",
        heading2SubHeading1Step3: "When the journey is {manuallyArchived}, its tasks remain in the list.",
        heading2SubHeading1Step4:
          'Care Partners can check the "archived" filter on the all journeys list to view archived members.',
        heading2SubHeading2: "For Members:",
        heading2SubHeading2Step1: "The member will not be notified that they have been archived",
        heading2SubHeading2Step2:
          "Member will still have access to the app (Care Plan, Resources, Content library, etc), but will not be able to contact their Care Partner.",
        heading2SubHeading2Step3: "The member will no longer receive the following communication from lifecycle:",
        heading2SubHeading2Step3Sub1: "Care Plan weekly progress report",
        heading2SubHeading2Step3Sub2: "Incomplete tracker reminders",
        heading2SubHeading2Step3Sub3: "Newsletters",
        heading2SubHeading2Step3Sub4: "Surveys",
        heading3: "Unarchived clients",
        heading3SubHeading1: "A journey can become unarchived if:",
        heading3SubHeading1Step1: "The client ran out of hours and has added hours to their account",
        heading3SubHeading1Step1Sub1: "Hours can be added by the client or their employer",
        heading3SubHeading2: "If a journey moves from Archived to Unarchived:",
        heading3SubHeading2Step1:
          "If the journey was archived because it lost Care Partner access, its Care Partner’s tasks will be added back to their Care Partners list with a due date of 2 *business days later",
        heading3SubHeading2Step2: "The member will resume receiving the following lifecycle communications:",
        heading3SubHeading2Step2Sub1: "Care Plan weekly progress report",
        heading3SubHeading2Step2Sub2: "Incomplete tracker reminders",
        heading3SubHeading2Step2Sub3: "Newsletters",
        immediately: "immediately",
        linkTitle: "More about archiving journeys",
        manuallyArchived: "manually archived",
        title: "Archiving"
      },
      archiveModal: {
        title: "Archive journey"
      },
      carePlans: "Care Plans",
      caringFor: "caring for {name}",
      expertAssignment: {
        title: "Reassign member"
      },
      expertsJourneyList: "Journey list",
      expertsManagerJourneyList: "{expertName}: Journey list",
      journeyCount: "{count, plural, one {Showing # journey} other {Showing # total journeys}}",
      nMore: "{count} more",
      newJourneyModal: {
        title: "New journey"
      },
      newJourneyStar: "This star icon will appear next to journeys you have not yet opened.",
      noCareRecipient: "in onboarding",
      popoverTitles: {
        addJourney: "Add new journey to member",
        archiveJourney: "Archive journey",
        deleteJourney: "Delete journey",
        journeyZero: "Unable to add a journey for this member because they have a balance of zero minutes",
        logTime: "Log time",
        openJourney: "Open journey",
        reassignClient: "Reassign member",
        scheduleConsult: "Schedule session",
        sendMessage: "Send message",
        unarchiveJourney: "Unarchive journey"
      },
      seeStatusDefinitions: "See status definitions",
      status: "Status",
      statuses: {
        active_for_renewals: {
          description:
            "The client has had meaningful interaction with the app in the last 90 days, to include logging into the app. Anything that creates activities for the activity feed and was initiated by the client is sufficient.",
          title: "Active for Renewals"
        },
        awaiting_initial_consult: {
          description: "They are signed up, awaiting an initial session",
          title: "Awaiting Initial Session"
        },
        awaiting_initial_consult_reschedule: {
          description: "They are signed up, awaiting an initial session and have a different canceled session",
          title: "Awaiting Initial Session Reschedule"
        },
        care_plan_in_progress: {
          description: "They have a published initial session report",
          title: "Care Plan in Progress"
        },
        care_plan_published: {
          description:
            "They have a published Care Plan. They will stay in this state for 2 weeks unless they schedule a follow up session.",
          title: "Care Plan Published"
        },
        definition: "Definition",
        engaged: {
          description:
            "They have a Care Plan that was published more than two weeks ago, have no future sessions scheduled, and have created meaningful activities in the last 60 days.",
          title: "Engaged"
        },
        follow_up_consult_scheduled: {
          description: "They have a published Care Plan and a future session",
          title: "Follow Up Session Scheduled"
        },
        inactive: {
          description:
            "They have a Care Plan that was published more than two weeks ago, have no future sessions scheduled, and have NOT created meaningful activities in the last 60 days.",
          title: "Inactive"
        },
        signed_up: {
          description: "They are signed up, and have none of the statuses below",
          title: "Signed Up"
        }
      },
      tableHeadings: {
        actions: "Actions",
        care_plan_last_updated: "Care Plan updated",
        client_name: "Name",
        created_at: "Date joined",
        expertRole: "{name}'s role",
        last_client_contact: "Last member contact",
        last_expert_contact: "Last GE contact",
        loved_one_name: "Loved one",
        myRole: "My role",
        new: "New",
        primary: "Owner",
        status: "Status"
      },
      team_member: "Supporting",
      title: "My journey list",
      unreadMessageTitle: "{count, plural, one {# unread message} other {# unread messages}}",
      zeroJourneyModal: {
        title: "Zero time balance",
        zeroBalance:
          "This member has used up their benefit. In order to add an additional journey, they will need to add more time to their account.",
        zeroBalanceBasic:
          "This member has a Basic Package which only includes support for one journey. If they would like to add an additional journey, they will need to add more time to their account. Note: If the first journey was created by error, or the situation has changed, you can edit the details of the first journey."
      }
    },
    manageExperts: {
      assignmentWeight: "Assignment weight",
      assignmentWeightExplanation:
        "If multiple Care Partners are a match for a member after specialties and timezones are taken into account, the assignment weights are used. For the remaining Care Partners, one will be chosen at random with the weights determining the probability of selecting each Care Partner. A higher weight means that the Care Partner is more likely to be chosen. A weight of zero means that they will never be assigned new members.",
      assignmentWeightLearnMore: "Learn more about assignment weight",
      tableHeadings: {
        actions: "Actions",
        client_count: "# Members",
        expert_coverage: "Coverage",
        journey_assignment_weight: "Assignment weight",
        name: "Name",
        specialties: "Specialties"
      },
      title: "Care Partner List"
    },
    message: {
      title: "Message"
    },
    messageTemplateCategories: {
      addCategory: "New category",
      create: "Create category",
      delete: "Are you sure you want to delete this message template category?",
      deleteDisabled: "There must be no templates attached to the category in order to delete it",
      editCategory: "Edit category",
      empty: "There are no categories here",
      new: "New category",
      tableHeadings: {
        actions: "Actions",
        title: "Name"
      },
      title: "Message template categories"
    },
    messageTemplates: {
      addTemplate: "New template",
      create: "Create template",
      delete: "Are you sure you want to delete this message template?",
      editCategories: "Edit categories",
      editTemplate: "Edit template",
      empty: "There are no templates here",
      new: "New template",
      preview: "Message preview",
      tableHeadings: {
        actions: "Actions",
        category: "Category",
        title: "Name",
        updated_at: "Modified"
      },
      title: "Message templates"
    },
    messages: {
      title: "Messages"
    },
    myConsult: "You ({firstName})",
    myRole: {
      backup: "You are a backup",
      owner: "You are the owner",
      primary: "You are the owner",
      team_member: "You are supporting"
    },
    namesRole: {
      backup: "{firstName} is a backup",
      owner: "{firstName} is the owner",
      primary: "{firstName} is the owner",
      team_member: "{firstName} is supporting"
    },
    outOfOffice: {
      addOutOfOfficeEvent: "Add OOO event",
      backupExpert: "Backup Care Partner",
      cancelConsult: "Cancel session",
      clear: "Clear",
      client: "Member: {clientName} ({employerName})",
      consultConflicts: "{count, plural, one {# session conflict} other {# session conflicts}}",
      consultConflictsFor: "Sessions conflicts for {name} OOO event",
      days: "{count, plural, one {# day} other {# days}}",
      deleteEvent: "Delete OOO event",
      duringEvent: "{count, plural, one {# session scheduled during event} other {# sessions scheduled during event}}",
      editEvent: "Edit OOO event",
      endTime: "End time (in the Care Partner's timezone):",
      eventScheduled: "Out of office event scheduled",
      expertOutOfTheOffice: "Care Partner out of the office",
      firstDay: "First day out",
      grayceHoliday: "Grayce holiday",
      heading: "Out of office",
      lastDayOut: "Last day out",
      lovedOne: "Loved one: {name} ({relationship})",
      outOfOfficeEvent: "Out of office event",
      reallyDelete: "Are you sure you want to delete this time off?",
      rescheduleConsult: "Reschedule session",
      rescheduleEmailSent: "Reschedule email was sent",
      sendRescheduleMessage: "Send reschedule message",
      startTime: "Start time (in the Care Partner's timezone):",
      tableHeadings: {
        actions: "Actions",
        backUpExpert: "Backup Care Partner",
        consultChecks: "Session checks",
        days: "Days",
        name: "Name",
        outOfOfficeDates: "Out of office dates"
      },
      title: "Out of office"
    },
    packageGrant: {
      createdAt: "Created at",
      dashboard: "Dashboard",
      eligibilityDate: "Eligibility date: {date}",
      enteredEmployer: "Entered employer",
      name: "Name",
      personalEmail: "Personal email",
      review: "Review: {name}",
      workEmail: "Work email"
    },
    resourceFeatureTemplate: {
      delete:
        "Are you sure you want to delete {title}? Doing so will have no effect on existing tasks that use it, but it will no longer be available for applying to new tasks.",
      title: "Resource feature template"
    },
    resourceFeatureTemplates: {
      addNew: "Add resource feature template",
      title: "Resource feature templates"
    },
    role: {
      backup: "Backup",
      owner: "Owner",
      team_member: "Supporting"
    },
    sharableUrls: {
      addLinks: "Add links",
      copied: "Sharable link copied",
      insertAppLink: "Insert app link",
      itemTitles: {
        account: "Account",
        articles: "Articles",
        carePlan: "Care Plan",
        community: "Community",
        consentForm: "Consent form",
        contactUs: "Contact us",
        drive: "Drive",
        employer_benefits: "Benefits hub",
        events: "Events",
        faq: "FAQ",
        goals: "Goals",
        help: "Help",
        legalDocuments: "Legal documents",
        library: "Library",
        marketplace: "Benefits hub",
        medicationTracker: "Medication tracker",
        message: "Messages",
        my_stories: "MyStories",
        pages: "Pages",
        privacyPolicy: "Privacy policy",
        profile: "Profile",
        providerTracker: "Provider tracker",
        quizzes: "Quizzes",
        resources: "Resources",
        scheduleConsult: "Schedule session",
        tasks: "Tasks",
        termsOfUse: "Terms of use",
        webinars: "Live events"
      },
      mergeTagWarnings: {
        "{companyName}": "Member's company not found.",
        "{expertFirstName}": "Care Partner's first name not found.",
        "{nextConsultTime}": "Next uncanceled session time not found.",
        "{ownerMemberFirstName}": "Owner member's first name not found.",
        "{pastConsultTime}": "Last uncanceled session time not found."
      },
      mergeTags: {
        placeholder: "Select an autofilled value",
        "{companyName}": "Member's company",
        "{expertFirstName}": "Care Partner's first name",
        "{nextConsultTime}": "Next uncanceled session time",
        "{ownerMemberFirstName}": "Owner member's first name",
        "{pastConsultTime}": "Last uncanceled session time"
      },
      placeholder: "Add links",
      title: "Select an app link"
    },
    tags: {
      title: "Tags"
    },
    tasks: {
      allTasks: "All tasks",
      areYouSure: "Are you sure you want to delete {title}?",
      cannotUpdateClientVisible:
        "This task becomes completed automatically when it has resources and the goal is marked active",
      clientInvisible: "Member cannot view this task.",
      clientVisible: "Member can view this task.",
      daily: "Daily",
      dailyTasks: "Daily tasks",
      days: {
        today: "Today",
        tomorrow: "Tomorrow",
        weekday: "{day}, {date}"
      },
      daysOverdue: "{days, plural, one {# Day} other {# Days}}",
      done: "Done",
      goalTasks: "Goal tasks",
      inProgress: "In Progress",
      newView: "Toggle to the new view",
      noTasksToday: "Nice job! You've finished all your tasks for today",
      oldView: "Toggle to the old view",
      overdue: "Overdue",
      popoverTitles: {
        deleteTask: "Delete task",
        editTask: "Edit task",
        markAsComplete: "Mark as complete",
        markAsIncomplete: "Mark as incomplete"
      },
      tableHeadings: {
        assignee: "Assignee",
        client: "Member",
        clientVisible: "Member-visible",
        dueDate: "Due date",
        due_time: "Due date",
        lovedOne: "Loved one",
        taskTitle: "Task title",
        yourRole: "Your role"
      },
      tasks: "{count, plural, one {# task} other {# tasks}}",
      time: {
        today: "Today {time}",
        tomorrow: "Tomorrow {time}",
        yesterday: "Yesterday {time}"
      },
      today: "Today",
      weekly: "Weekly",
      weeklyTasks: "Weekly tasks"
    },
    timeLogs: {
      title: "Time tracking"
    }
  },
  expertDashboardCPTurnAroundTime: {
    statusDefinitions: "Client status definitions"
  },
  expertJourney: {
    carePlan: {
      publishCarePlan: "Publish care journey",
      title: "Care Plan",
      unpublishableReason: "Add a stage, summary, schedule, and at least one current goal to publish the care journey"
    },
    carePlanEdit: {
      title: "Edit Care Plan"
    },
    careProfile: {
      title: "Profile"
    },
    consults: {
      scheduleConsult: "Schedule session",
      title: "Session"
    },
    documents: {
      title: "Documents"
    },
    employer: {
      address: "Address:",
      benefits: "Benefits",
      benefitsHidden: "Benefits hidden:",
      billableCalculation: "Billable calculation:",
      billableClientsOfPurchased: "Billable members of {purchased} purchased in current refresh period",
      carePlanPlusHours: "Care Plan + {hours} hours",
      contractEnd: "Contract end:",
      contractStart: "Contract start:",
      copied: "Copied!",
      culture: "Other context on employer culture:",
      eligibilityMethod: "Eligibility method:",
      employeeCountByCountries: "Employee count by country",
      enrollmentCode: "Enrollment code:",
      expectedUtilization: "Expected utilization:",
      expiration: "Expiration:",
      fullTimeEmployees: "Full-time employees",
      globalPopulation: "Global population:",
      handoffMethod: "Handoff method",
      hoursPurchased: "{hours} hours",
      industry: "Industry:",
      links: "Useful links",
      otherNotes: "Other notes",
      registeredClients: "Registered members",
      title: "Employer",
      website: "Website:"
    },
    goalTemplates: {
      title: "Goals"
    },
    internalNotes: {
      ROADMAP: "Plan",
      SYNOPSIS: "Synopsis",
      allInternalNotes: "All internal notes",
      consultReports: "Session reports",
      initialAssessment: "Initial assessment",
      internalNotes: "Internal notes",
      lastUpdated: "Last updated: {date}",
      roadmap: "Plan",
      roadmapDescription: "Describe your assessment, including the next steps and plan",
      summary: {
        caregivers: "Caregivers",
        closeSummary: "Close AI summary",
        headline: "AI summary",
        lovedOne: "Loved one",
        nextContact: "Recommended next contact",
        showFullSummary: "Show full AI summary",
        stepsTaken: "Steps taken",
        summary: "Summary",
        supportTopics: "Support topics",
        tags: "Recommended tags"
      },
      synopsis: "Synopsis",
      synopsisDescription: "Add a brief summary of the member's current situation and needs",
      title: "Internal notes",
      updatedByExpert: "Updated by: {expertName}",
      versionHistoryFor: "Version history for {kind}",
      viewHistory: "View history"
    },
    messages: {
      messagesExplainer: "New member messages",
      title: "Messages"
    },
    timeLogs: {
      title: "Time logs"
    }
  },
  expertSection: {
    canceled: "Canceled",
    description1: "Pick a time, and we’ll figure this out together. ",
    description2: "Sessions can be 30 or 60 minutes with a video option.",
    dontMiss: "Don’t miss your upcoming sesssion",
    expertBio: "Care Partner bio",
    extraInfo: "Extra session information",
    goToReport: "Go to report",
    helpTopics: {
      plan: {
        1: "Create a realistic roadmap for the future",
        2: "Role play important conversations",
        3: "Help keep a loved one safe at home",
        4: "Review important legal or financial documents",
        title: "Plan"
      },
      research: {
        1: "Find medical and mental health professionals",
        2: "Evaluate living arrangements",
        3: "Evaluate childcare options",
        4: "Compare cost of services",
        title: "Research"
      },
      schedule: {
        1: "Hire a caregiver",
        2: "Set up meal delivery or transportation",
        3: "Book cleaning service",
        title: "Schedule"
      },
      support: {
        1: "Help balance responsibilities",
        2: "Foster social connections",
        3: "Help you care for yourself while helping others",
        title: "Support"
      }
    },
    hi: "Hi, I'm {name}",
    historyAndUpcoming: "History and upcoming sessions",
    howICanHelp: "How I can help you",
    inProgress: "In progress",
    join: "Join",
    lightenLoad: "Grayce Care Partners can lighten the load in your personal life",
    message: "Message",
    more: "More about Me",
    myGoal:
      "My goal is to keep you from feeling overwhelmed in stressful personal situations so you can show up as your full self at work and at home.",
    nextRecommended: "Next recommended",
    nextRecommendedSession: "Next recommended session",
    readMore: "Read more",
    reschedule: "Reschedule",
    returnToExpert: "Return to Care Partner",
    saveTime: "I can save you time, money, and energy by connecting you with personalized resources.",
    schedule: "Schedule",
    scheduled: "Scheduled",
    sessionDetails: "Session details",
    sessionHistory: "Session history",
    sessions: "Sessions",
    slides: {
      plan: {
        description:
          "When navigating care for a child, adult, or yourself, we can help you avoid common pitfalls and see what's ahead. We will compare costs of services, handle referrals, help with transitions and give you an unbiased opinion.",
        title: "Plan ahead"
      },
      research: {
        description:
          "Whether you need a cleaning service, lawyer, doctor, or something else, Grayce Care Partners can find it. Let us vet and recommend the best services, saving the headache.",
        title: "Research and Recommend"
      },
      time: {
        description:
          "Grayce Care Partners can save you time by arranging rides. scheduling appointments, setting up food delivery services, and much more!",
        title: "Save time"
      }
    },
    title: "Support starts with a single call",
    today: "Today",
    tomorrow: "Tomorrow",
    viewInfo: "Session info"
  },
  explore: {
    back: "Back to Explore",
    community: {
      title: "Community"
    },
    events: {
      activateToRegister: "Activate now",
      activateToRegisterExplainer: "Activate your free benefit to register.",
      bannerDescription: "Browse Grayce events...",
      bannerTitle: "Events",
      byGrayce: "By Grayce",
      cancelFutureRegistrations:
        "You are registered for {count, plural, one {# other event} other {# other events}} in this series. Would you like to cancel future registrations?",
      checkEmail: "Please check your email for details. We can’t wait to see you.",
      completeRegistration: "Complete registration for ‘{title}’",
      details: "Details",
      hostedByGather: "Hosted by Gather",
      joinInstructionsFromZoom: "Once submitted, you will receive instructions on how to join the event through Zoom",
      noKeepFutureRegistrations: "No, keep my recurring registration",
      onlineEvent: "Online event",
      pastTitle: "On-demand events",
      register: "Register for event",
      registered: "You're registered",
      registeredSuccess: "You have successfully registered for this live event!",
      startTime: "Start time",
      submitRegistration: "Submit registration",
      unregister: "Cancel registration",
      upcomingTitle: "Upcoming events",
      yesCancelFutureRegistrations: "Yes, please cancel my recurring registration",
      youHaveBeenRemoved: "You have been removed from this live event"
    },
    exploreResources: "Explore Grayce resources",
    marketplace: {
      bannerDescription:
        "Browse and enroll in additional employee benefits and vetted support services all in one place.",
      bannerTitle: "Grayce benefits hub",
      employerSponsored: "Employer sponsored",
      signUp: "Sign up",
      title: "Benefits hub"
    },
    media: {
      selectedForYou: "Selected for you",
      title: "Library"
    },
    return: "Return to Explore"
  },
  fakeGoogleAuth: {
    failure:
      "Whoops, that didn't work. Check that you entered a valid email address and that your environment allows fake authentication"
  },
  goalTemplates: {
    aboutGoals: "About goals",
    add: "Add it to your Care Plan",
    addGoal: "Set goal",
    addYourFirstGoal: "Add your first goal:",
    added: "Added!",
    backToCarePlan: "Back to Care Plan",
    check: "Check off action items to complete your goal",
    goalAdded: "Goal added",
    goalDetails: "Goal details",
    goalLibrary: "Goal library",
    goalsDescription:
      "We know that life events can be all consuming. Break overwhelming projects into manageable steps by setting a goal.",
    introDescription: "You can break down overwhelming projects into these manageable action plans.",
    introTitle: "We know that life events can be all consuming",
    onYourTeam:
      "Remember, we are here to help. Book a session for Care Partner advice, whatever your situation or location.",
    reviewCarePlan: "Review Care Plan",
    search: "Browse goals or search by keyword",
    stepOne: "Browse goals or search by keyword",
    stepThree: "Review your Care Plan to personalize your goals",
    stepTwo: "Add it to your Care Plan",
    toGetStarted: "To get started:",
    viewDetails: "View details"
  },
  googleOAuthCallback: {
    contactSupport: "contact support@withgrayce.com",
    explanationPart1:
      "We seem to have had a problem connecting to your Google account. This happens sometimes, and usually trying again will fix it. You can ",
    explanationPart2: " and try again. If this problem keeps happening please ",
    explanationPart3:
      " for assistance. You can also try logging in with your password if you have one, or using the password reset feature if you don't.",
    title: "Whoops, that didn't work",
    tryAgainLink: "head back to the sign in page"
  },
  home: {
    accessBenefits: "Access your benefits or other services vetted by Grayce",
    andWantHelpWith: "and want help with my",
    attendWebinar: "Attend a live event or learn from our community",
    browseLibrary: "Browse the library",
    connect: "Connect with others",
    description: "Care for loved ones with Grayce on your team.",
    discover: "Discover advice and resources from our Care Partners",
    exploreContent: "Explore content",
    iAm: "I am",
    justForYou: "Just for you",
    links: {
      document: "View documents",
      gather: "Join Gather",
      library: "Browse the library",
      marketplace: "Visit the benefits hub",
      plan: "Start a custom plan",
      tracker: "Begin a tracker"
    },
    lovedOnes: "loved one's",
    needs: "needs",
    peaceOfMind: "Personalized resources offer peace of mind",
    selectRole: "select role",
    selectYourRole: "Select your role",
    tellUs: "Tell us about yourself",
    title: "Support designed just for you",
    titles: {
      document: "Organize and securely share files",
      gather: "Attend live events or connect with others",
      library: "Explore Care Partner articles and videos",
      marketplace: "Access employer benefits",
      plan: "Figure out your next steps and future needs",
      tracker: "Manage medications and key contacts"
    },
    viewDetails: "View details",
    viewMore: "View more",
    visitMarketplace: "Visit the benefits hub"
  },
  internalNoteTemplates: {
    reallyDelete: "Are you sure you want to delete this internal note template?",
    tableHeadings: {
      actions: "Actions",
      author: "Author",
      created_at: "Created at",
      title: "Title",
      updated_at: "Updated at"
    }
  },
  learn: {
    learn: "Library",
    personalizedForYou: "Personalized for you",
    results: "{count, plural, one {# Result} other {# Results}}",
    seeMore: "See more",
    yourExpertRecommends: "Your Care Partner recommends"
  },
  legalDocuments: {
    completedDocuments: "Completed documents",
    details: "Details",
    importantLegalDocuments: "Important legal documents",
    incompleteDocuments: "Incomplete documents",
    list: {
      attachments: "Attachments",
      notes: "Notes",
      storageLocation: "Storage location",
      updateDate: "Date updated"
    },
    markAsComplete: "Mark as complete",
    notStarted: "Legal document tracker has not been started",
    started: "Legal documents tracker was started {date}, but has not been completed.",
    updateDate: "Date updated: {date}"
  },
  medicationsDownload: {
    covered_by_insurance: "Covered by insurance",
    dateOfBirth: "Date of birth: {date}",
    dosageAndFrequency: "Dosage and frequency",
    dosageLabel: "Dosage and frequency (e.g. 5 mg, twice daily)",
    formatLabel: "Form",
    instructionsLabel: "Instructions (e.g. take with food)",
    lastUpdated: "Last updated {date}",
    medicationList: "Medication list",
    medicationTracker: "Medications",
    medicationTypeLabel: "Medication type",
    name: "Name",
    nameLabel: "Medication name",
    notesLabel: "Additional notes",
    overTheCounter: "Over-the-counter ({count})",
    page: "Page {pageNumber} of {totalPageNumber}",
    paymentTypeLabel: "Payment type",
    pharmacyLabel: "Pharmacy",
    prescriber: "Prescriber",
    prescriberLabel: "Prescribing clinician",
    prescriptionMedication: "Prescription medications ({count})",
    prescriptionNumberLabel: "Rx #",
    questions: "If you have any questions, reach out to your Grayce Care Partner at app.withgrayce.com",
    reasonForTaking: "Reason for taking",
    reasonForTakingLabel: "Indication (Reason for taking)",
    refillFrequencyLabel: "Refill frequency",
    self_pay: "Not covered. Self pay.",
    summary: "Summary",
    unknown: "Unknown",
    vitaminsAndSupplements: "Vitamins & supplements ({count})"
  },
  messages: {
    allThreads: "All threads",
    andXOthers: "and {count} others",
    attachments: "Attachments",
    careTeam: "care team",
    contactSupport: "Contact support@withgrayce.com",
    disclaimer:
      "Grayce is not acting as a medical provider nor is it providing clinical care. Information provided by Grayce is not an endorsement or recommendation of any course of clinical treatment. Please contact your treating health care provider(s) if you have any questions related to medical or mental health conditions or treatment services.",
    haveAQuestion: "Have a question?",
    hoursUsed: "You have used up the hours that your employer has purchased for you.",
    newMessage: "New message",
    noClients: "No one else is on your care team. To add someone, go to your {careTeam} under your Care Plan.",
    purchaseHours: "Purchase hours",
    sendButton: "Send message",
    templateErrors: "This template has the following autofilled values that does not exists:",
    title: "Messages",
    typeName: "Type name..."
  },
  myStory: {
    reallyDelete: "Are you sure you want to delete this MyStory?"
  },
  navigatingCarePlan: {
    bereavement: {
      p1: "When your loved one has passed away, you often have a lot on your plate, emotionally and logistically. You are grieving, planning remembrances, and administering your loved one’s estate.",
      p2: "Your Grayce Care Partner will be there to help you through it all, and ready with any guidance you need on administrative and logistical decisions.",
      title: "The bereavement stage"
    },
    crisis: {
      p1: "Your loved one may experience moments of crisis, where they’re in need of urgent care or assistance.",
      p2: "As a caregiver in these situations, it’s often you who chooses a course of action, communicates your loved one’s wishes to providers, and arranges for support services. Your Grayce Care Partner will be there to help you navigate all these challenges.",
      title: "The crisis stage"
    },
    dependent: {
      p1: "Your loved one now has multiple areas of need, and they may be living with advanced medical conditions. They’ll most likely require help with their day-to-day tasks and personal care.",
      p2: "As a caregiver, you’re now dedicating significant time to helping your loved one—maybe even overseeing their medical and financial affairs. At this stage, your Grayce Care Partner will help you find solutions for your loved one’s increasing needs.",
      title: "The dependent stage"
    },
    end_of_life: {
      p1: "At this stage, your loved one is near the end of their life. They are saying goodbye to friends and family, grieving, and preparing.",
      p2: "This can be an especially difficult time for caregivers, too. You are also saying goodbye, carrying out your loved one’s last wishes, and providing emotional support. Your Grayce Care Partner will support you through it, and they can help you choose and arrange for hospice care as needed.",
      title: "The end-of-life stage"
    },
    independent: {
      p1: "Your loved one is living independently without significant care needs, and they’re maintaining any existing medical conditions.",
      p2: "For you as a caregiver, now is a good time to begin a conversation about future care goals. Your Grayce Care Partner can help you start to plan, and they’ll gather information to set you up for success as you progress through your care journey.",

      title: "The independent stage"
    },
    interdependent: {
      p1: "Your loved one is beginning to show signs that they need help with their care needs. They’re still maintaining their existing medical conditions, but now is the time to start evaluating and choosing services that will help them in the future.",
      p2: "As a caregiver at this stage, you’re probably increasingly involved with your loved one’s care and decision-making. Your Grayce Care Partner will help you research, evaluate, and plan for ways to help them stay as safe and independent as possible.",
      title: "The interdependent stage"
    },
    p1: "As our loved ones start to need more help, we begin a journey too: one of providing more support and care. It’s a journey that has certain universal stages, and they each come with their own goals and needs.",
    p2: "Not everyone will go through every stage, and these stages may not always happen in the same order. Even so, it’s helpful to know which stage we’re in—and you can take comfort in knowing that so many other people are navigating similar experiences.",
    subTitle: "Know the stages",
    title: "Navigating your care journey"
  },
  newPassword: {
    header: "Reset password"
  },
  notInterested: {
    informationRemoved: "Your information has been removed.",
    weAreHere: "We are here when you need us ❤️"
  },
  oauth: {
    agree: "Agree",
    agreeTo: "You agree that {name} will be able to:",
    header: "Logging you in with your Grayce account",
    scopes: {
      profile: {
        description:
          "Your first name, time zone and loved one's relationship to you will be visible to other Gather members",
        title: "Share your profile data with other Gather members"
      }
    }
  },
  onboarding: {
    steps: {
      acuityChronic: {
        ADULT: {
          subtitle:
            "This can include things like a physical, developmental, or intellectual disability, a medical condition, mental illness, infertility, or neurodivergence. A long-term condition is anything over 60 days.",
          title: "Does your loved one have a long-term or lifelong condition?"
        },
        AGING_LOVED_ONE: {
          subtitle:
            "This can include things like a physical, developmental, or intellectual disability, or a medical condition such as Alzheimer's, dementia, or diabetes. A long-term condition is anything over 60 days.",
          title: "Does your loved one have a long-term or lifelong condition?"
        },
        CHILD: {
          subtitle:
            "This can include things like a physical, developmental, or intellectual disability, diabetes, asthma, mental illness, and neurodivergence. A long-term condition is anything over 60 days.",
          title: "Does your loved one have a long-term or lifelong condition?"
        },
        INFANT: {
          subtitle:
            "This can include things like a physical, developmental, or intellectual disability, diabetes, asthma, mental illness, and neurodivergence. A long-term condition is anything over 60 days.",
          title: "Does your loved one have a long-term or lifelong condition?"
        },
        self: {
          subtitle:
            "This can include things like a physical, developmental, or intellectual disability, a medical condition, mental illness, infertility, or neurodivergence. A long-term condition is anything over 60 days.",
          title: "Do you have a long-term or lifelong condition?"
        }
      },
      acuityComplex: {
        ADULT: {
          subtitle:
            "This can include things like a serious cancer diagnosis, more than one medical condition, or a mental health hospitalization.",
          title: "Does your loved one have a life-threatening condition or multiple medical conditions? "
        },
        AGING_LOVED_ONE: {
          subtitle:
            "This can include a serious diagnosis like cancer, end-stage renal disease, advanced cardiovascular disease, or multiple medical conditions.",
          title: "Does your loved one have a life-threatening condition or multiple medical conditions? "
        },
        CHILD: {
          subtitle:
            "This can include things like genetic disorders, premature birth, congenital defects, heart conditions, pediatric cancer, or multiple medical conditions.",
          title: "Does your loved one have a life-threatening condition or multiple medical conditions? "
        },
        INFANT: {
          subtitle:
            "This can include things like genetic disorders, premature birth, congenital defects, heart conditions, pediatric cancer, or multiple medical conditions.",
          title: "Does your loved one have a life-threatening condition or multiple medical conditions? "
        },
        self: {
          subtitle:
            "This can include things like a serious cancer diagnosis, more than one medical condition, or a mental health hospitalization.",
          title: "Do you have a life-threatening condition or multiple medical conditions? "
        }
      },
      acuityDaily: {
        ADULT: {
          subtitle:
            "This can include things like routine medical care, day-to-day care logistics like meal delivery and transportation, and legal and financial planning for the future.",
          title: "Does your loved one need support with day-to-day care?"
        },
        AGING_LOVED_ONE: {
          subtitle:
            "This can include things like routine medical care, day-to-day care logistics like meal delivery and transportation, and legal and financial planning for the future.",
          title: "Does your loved one need support with day-to-day care?"
        },
        CHILD: {
          subtitle:
            "This can include things like routine medical care, educational support, day-to-day logistics like childcare, and legal and financial planning for the future.",
          title: "Does your loved one need support with day-to-day care?"
        },
        INFANT: {
          subtitle:
            "This can include things like routine medical care, educational support, day-to-day logistics like childcare, and legal and financial planning for the future.",
          title: "Does your loved one need support with day-to-day care?"
        },
        self: {
          subtitle:
            "This can include things like routine medical care, day-to-day care logistics like meal delivery and transportation, and legal and financial planning for the future.",
          title: "Do you need support with day-to-day care?"
        }
      },
      acuityEpisodic: {
        ADULT: {
          subtitle: "This can include things like a car accident, fall, sports injury, or other injury.",
          title: "Has your loved one had an accident or isolated incident in the last 60 days?"
        },
        AGING_LOVED_ONE: {
          subtitle: "This can include things like an car accident, fall, or other injury.",
          title: "Has your loved one had an accident or isolated incident in the last 60 days?"
        },
        CHILD: {
          subtitle: "This can include things like an accident or injury.",
          title: "Has your loved one had an accident or isolated incident in the last 60 days?"
        },
        INFANT: {
          subtitle: "This can include things like an accident or injury.",
          title: "Has your loved one had an accident or isolated incident in the last 60 days?"
        },
        self: {
          subtitle: "This can include things like a car accident, fall, sports injury, or other injury.",
          title: "Have you had an accident or isolated incident in the last 60 days?"
        }
      },
      carePhase: {
        subtitle: "No matter what, Grayce can help.",
        title: "Which best describes your care situation?"
      },
      clientLocation: {
        placeholder: "Postal code, neighborhood, or address",
        subtitle:
          "In order to provide virtual session times and custom resources, we need to know the location where you live. ",
        title: "Where are you located?"
      },
      complete: {
        title: {
          1: "Creating your account",
          2: "Matching you to a Care Partner",
          3: {
            employer: "Verifying your benefits",
            selfPaid: "Finishing up"
          }
        }
      },
      exitTransition: {
        footerBubble: {
          1: "Gain confidence",
          2: "Save money",
          3: "Reduce stress and worry",
          4: "Save time"
        },
        subtitle:
          "We know it takes a lot to care for loved ones, whether it’s once in a while or every day. Next, tell us how we can save you time and energy so you can show up as your best self at work and home.",
        title: "We step in to support"
      },
      intro: {
        cta: "Get started",
        subtitle:
          "Answer a few questions so we can match you to the right Care Partner and resources that will help meet your needs.",
        title: {
          employerSponsored: "Set up your account to complete benefit enrollment",
          selfPaid: "Set up your account to complete enrollment"
        }
      },
      lovedOneLocation: {
        grieving: {
          subtitle:
            "In order to provide custom resources, we need to know the location where your late loved one lived.",
          title: "Where was your late loved one located?"
        },
        notGrieving: {
          subtitle: "In order to provide custom resources, we need to know the location where your loved one lives.",
          title: "Where is your loved one located?"
        },
        placeholder: "Postal code, neighborhood, or address"
      },
      lovedOneName: {
        grieving: {
          title: "What was your loved one's name?"
        },
        notGrieving: {
          title: "What is your loved one's name?"
        }
      },
      needsFromGrayce: {
        subtitle: "Select all that apply.",
        title: "What are you interested in Grayce helping with?"
      },
      pregnancyHighRisk: {
        subtitle:
          "High-risk pregnancies include factors like multiples, pre-existing medical conditions, gestational diabetes, placental problems, or other risk factors.",
        title: "Is the pregnancy high-risk?"
      },
      relationshipToLovedOne: {
        default: {
          subtitle: "They are:",
          title: "How are you related?"
        },
        grieving: {
          subtitle: "They are:",
          title: "We are so sorry for your loss. How are you related to your late loved one?"
        },
        pregnancy: {
          title: "Who is pregnant?"
        }
      },
      timezone: {
        title: "What is your time zone?"
      },
      useCasePreferences: {
        subtitle: "Our team has years of expertise and can help no matter the life-stage.",
        subtitle2: "Select all that apply.",
        title: "What can we support you with?"
      },
      useCaseSelection: {
        subtitle: "You will be able to set up additional profiles later.",
        title: "Which profile do you want to set up?"
      }
    }
  },
  onboardingIneligible: {
    header: "Your employer doesn't offer Grayce",
    p1: "Thank you for expressing your interest in Grayce. Unfortunately, it looks like your employer does not offer Grayce as a benefit yet. Please feel free to reach out to your employer’s HR team about this benefit, or you can fill out this form and we will reach out to your employer about this benefit.",
    p2: "If you have any questions in the meantime, please",
    reachOutToUs: "reach out to us",
    requestGrayce: "Request Grayce benefits"
  },
  onboardingQuestions: {
    createCarePlan: "Create my Care Plan",
    firstStepComplete:
      "The first step is complete, and you are on your way to getting Care Partner support. Next, book a session with {expertName}, your Grayce Care Partner, for personalized guidance.",
    header: "Please tell us more so we can help.",
    hereForYou: "We're here for you.",
    makeTheMost:
      "Your Care Partner will still be here to help you make the most of Grayce when you're ready. For now, we just need a little more information to create a personalized Care Plan for you. You can schedule a session from the app at any time.",
    noProblem: "No problem, we're still here for you.",
    openCarePlan: "View Care Plan",
    personalizedCarePlan:
      "Your personalized Care Plan is ready! Remember: You can always book a session for Care Partner guidance and concierge support.",
    position: "Section {index} of {count}",
    skipForNow: "Skip For Now",
    thanksForSharing: "Thanks for sharing!",
    wellDone: "Well done!"
  },
  onboardingVerifyEmail: {
    contactUs: "contact us.",
    header: "Verify your email",
    p1: "An email was sent to {email}. Please verify your account by clicking the link in that email.",
    p2: "If you did not receive an email or your email address is incorrect,",
    resendEmail: "Resend email"
  },
  onboardingWaitingForApproval: {
    contentBoxCopy:
      "Think of Grayce as your Care Partner guide and concierge. We're here because you care. We help you to navigate needs for aging, ill, and vulnerable loved ones. Whether you're planning care for yourself or a loved one, supporting ongoing needs, or navigating transitions, Grayce is here to guide and support you through every step of your care journey.",
    forQuestionsContact: "For questions, contact",
    header: "Welcome to Grayce!",
    header2:
      "We are working quickly to verify your eligibility. If your account is eligible, you will receive a confirmation email soon.",
    header2Capped:
      "This program is currently at full capacity. Please contact your employer's human resources department to request a spot. If your account becomes eligible, you will receive an email notification.",
    whatIsGrayce: "What is Grayce?"
  },
  pageNotFound: {
    contactSupport: "contact support@withgrayce.com.",
    explanation: "Sorry, this page doesn't exist! If you're having trouble, please ",
    title: "Page not found"
  },
  profile: {
    addOrRemove: "Add or remove support team members",
    basicInfoDescription: "Tell us about your needs",
    basicInfoTitle: "Basic information",
    cancelInvite: "Cancel invite",
    editAccount: "Edit {name}'s details",
    editMyAccount: "Edit my details",
    editSupportTeam: "Edit support team",
    email: "Email",
    myProfile: "My profile",
    namesProfile: "{name}'s profile",
    pending: "Pending",
    resendInvite: "Resend invite",
    supportTeamDescription: "You can share your Grayce benefit with others who help care for your loved one",
    supportTeamTitle: "Support team"
  },
  resendConfirmWorkEmail: {
    checkEmail:
      "If {email} matches the email you used when signing up then an email has been sent. Recieve your new Grayce benefit package by clicking the link in that email.",
    description:
      "Your Grayce account is eligible for an upgrade with more time to access your Care Partner. Click the button below to resend the confirmation to your work email.",
    failed: "Failed to resend confirmation email",
    header: "Add your new Grayce benefit package",
    sent: "Email sent!"
  },
  resetPassword: {
    explanation:
      "Don’t worry, we’re here for you. Enter your email address below, and we'll send you an email with instructions on how to reset your password.",
    returnToLogin: "Return to sign in",
    title: "Forgot password?"
  },
  resetPasswordSuccess: {
    explanation: {
      contactSupportLink: "contact support@withgrayce.com.",
      p1: "If an account exists, a password reset link was sent to {email}.",
      p2: "If you don’t see the email, check your junk folder. The email was sent from app@mail.withgrayce.com.",
      p3: "If you need help, "
    },
    title: "Check your email"
  },
  safetyReport: {
    areYouSure: "Are you sure you want to retake your safety quiz? Your previous answers will be reset.",
    beginQuiz: "Begin home safety quiz",
    closingPage: {
      inTheMeantime: "In the meantime, if you have any additional questions {sendMessage}.",
      niceWork:
        "Nice work! You’re all done. At your next session, your Care Partner will provide a custom action plan and help you prioritize what to tackle first.",
      recommendedReading: "Recommended reading",
      sendAMessage: "send a message"
    },
    completedOn: "Completed on {date}",
    continueQuiz: "Continue home safety quiz",
    evaluateSafety: "Evaluate the safety of your loved one’s living situation.",
    hideAnswers: "Hide my answers",
    inProgress: "Your home safety quiz is in progress, but you have a little more to do.",
    notStarted: "Safety quiz has not been started.",
    openArticle: "Open article: home safety checklist",
    retakeQuiz: "Retake quiz",
    section: "Section {count} of {total}",
    started: "Safety quiz was started but has not been completed.",
    viewAnswers: "View my answers",
    viewSafetyQuestions: "View safety quiz answers"
  },
  timeLogs: {
    tableHeadings: {
      activity: "Activity",
      balance: "Balance",
      client: "Member",
      date: "Date",
      notes: "Notes",
      support: "Support",
      time: "Time"
    }
  },
  timesheets: {
    tableHeadings: {
      billableHours: "Billable",
      email: "Email",
      name: "Name",
      submission: "Submission",
      totalHours: "Total hours",
      totalPay: "Total pay"
    },
    viewTimesheet: "View timesheet"
  },
  webinar: {
    reallyDelete: "Are you sure you want to delete this live event?"
  }
};
/* eslint-enable @typescript-eslint/naming-convention */

export default pages;
