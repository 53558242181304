/* eslint-disable @typescript-eslint/naming-convention */
const forms = {
  activityFilters: {
    actorKindLabel: "Actor",
    clientIdLabel: "Member",
    sectionLabel: "Section",
    startTimeLabel: "Time period"
  },
  admin: {
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    timezoneLabel: "Timezone",
    zoomIdLabel: "Zoom ID"
  },
  adminArticleFilters: {
    contentConditionSource: "Trigger source",
    focusArea: "Focus area",
    search: "Search"
  },
  adminClientFilters: {
    eligibilityStatusLabel: "Category",
    employerIdLabel: "Employer"
  },
  adminEmployeeFilters: {
    search: "Search"
  },
  adminJourneyFilters: {
    activeLabel: "Active",
    categoryLabel: "Category",
    consultCompleteLabel: "Session complete",
    employerLabel: "Employer",
    expertIdLabel: "Primary Care Partner",
    searchLabel: "Search"
  },
  article: {
    blurbLabel: "Blurb",
    caregiverEmailLabel: "Caregiver email",
    caregiverFirstNameLabel: "Caregiver first name",
    caregiverLastNameLabel: "Caregiver last name",
    contentTypeLabel: "Content type",
    countriesLabel: "Countries",
    countriesSubLabel: "Search to add a country or geography",
    focusAreasLabel: "Focus areas",
    geographyLabel: "Geography",
    hiddenLabel:
      "Hide the article from the library, searches, and recommendations. Use this to make the article sharable but not appear in the app.",
    hostedByGatherLabel: "Hosted by Gather",
    photoBlobIdLabel: "Image (square)",
    photoHelp: "The aspect ratio should be somewhere between to 2:1 and 2.8:1 (width:height).",
    previewImageBlobIdLabel: "Preview image for the video",
    previewImageHelp: "The aspect ratio should be the same as that of the video.",
    quoteLabel: "Block quote",
    recurringWebinarExplanationBullet1:
      "We use Zoom to create these recurring meetings, and the way they are scheduled follows Zoom's rules",
    recurringWebinarExplanationBullet2:
      "Changing the recurring settings after creating a Live Event can be dangerous. If there are future events and you change the rules so that they would be at a different time or date, Zoom will remove them and their registrations, and we will remove the registrations as well. If the schedule changes over time, you will need to plan ahead. The best approach for handling this would be to reduce the number of occurrences on the existing Live Event and then to create a new one using the new schedule.",
    recurringWebinarExplanationBullet3:
      "You can choose if you would like the meetings to occur daily, weekly, or monthly. For each of these types, you can also specify the time interval between events. To schedule a quarterly meeting, for example, select monthly and then set the number of months between events to 3. To schedule an event that occurs every day, week, or month, set the interval to 1.",
    recurringWebinarExplanationBullet4:
      "The live event start time has a complicated relationship with recurring events. It always dictates the time of day at which the recurring meetings occur, but the date you provide is simply the earliest date at which the occurrences could start. For example, if you set the start time to 2/26 at 6:00PM and then choose to have monthly events on the first Friday of the month, the first occurrence of the meeting will be on the first Friday in March at 6:00PM.",
    recurringWebinarExplanationBullet5:
      "Zoom does not support infinitely recurring events. You must choose a number of events to schedule, and you cannot schedule more than 60",
    recurringWebinarExplanationBullet6:
      "Once you make a Live Event recurring, you will not be able to change it to non-recurring because Zoom does not support this",
    recurringWebinarExplanationIntro:
      "Recurring live events are complicated, please read this explanation prior to scheduling one. Some things you should know:",
    sourcesLabel: "Sources",
    tagsLabel: "Tags",
    textLabel: "Content",
    titleLabel: "Title",
    transcriptLabel: "Transcript",
    useCasesLabel: "Use cases",
    videoBlobIdLabel: "Video",
    videoHelp: "MP4 videos only.",
    webinarCompletedLabel: "Has this live event already occurred?",
    webinarDurationLabel: "Live event duration (in minutes)",
    webinarRecurEndTimesLabel: "Number of occurrences to schedule (max 60)",
    webinarRecurMonthlyDayLabel: "On what day of the month should the events occur?",
    webinarRecurMonthlyTypeLabel: "When should the events occur each month?",
    webinarRecurMonthlyWeekLabel: "In which week of the month should the events occur?",
    webinarRecurMonthlyWeekdayLabel: "On which day of the week should the events occur?",
    webinarRecurRepeatIntervalLabel: {
      DAILY: "How many days should there be between events?",
      MONTHLY: "How many months should there be between events?",
      NONE: "How often should the event repeat?",
      WEEKLY: "How many weeks should there be between events?"
    },
    webinarRecurTypeLabel: "Recurring meeting type",
    webinarRecurWeeklyDaysLabel: "On what days of the week should the event occur?",
    webinarStartTimeLabel: "Live event start time"
  },
  automation: {
    liveLabel: "Live",
    tagsLabel: "Tags",
    titleLabel: "Title"
  },
  automationActionBlock: {
    archiveTagLabel: "Archive tag",
    carePlanTaskTemplateIdLabel: "Template",
    durationLabel: "Task due in time",
    durationUnitsLabel: "Task due in time units",
    goalTemplateIdLabel: "Goal",
    kindLabel: "Action type",
    messageContentLabel: "Message content",
    pushNotificationActionLabel: "Action to take when clicking on the push notification",
    pushNotificationContentLabel: "Push notification content",
    tagLabel: "Tag",
    taskDueDaysLabel: "Days until task is due",
    taskDueHourLabel: "Hour when task is due, in Care Partner timezone",
    taskKindLabel: "Type of task",
    taskTitleLabel: "Task title",
    userTypeLabel: "Apply only to these users"
  },
  automationCondition: {
    add: "Add condition",
    careProfileFieldLabel: "Care profile field",
    careProfileValuesLabel: "Value",
    durationLabel: "Length of time",
    durationUnitsLabel: "Time units",
    employerIdLabel: "Employer",
    kindLabel: "Condition type",
    update: "Update condition"
  },
  automationConditionGroup: {
    operationLabel: "Operation"
  },
  automationTimingBlock: {
    durationLabel: "Length of delay",
    durationUnitsLabel: "In"
  },
  automationTriggerBlock: {
    activityKindLabel: "Type of activity",
    addAKeyword: "Add a keyword",
    addAnotherKeyword: "Add another keyword",
    articleIdLabel: "Article",
    careProfileFieldLabel: "Care profile field",
    careProfileValuesLabel: "Values",
    consultReportKindLabel: "Session type",
    durationLabel: "How much time?",
    durationUnitsLabel: "Time units",
    freeTextExplanation1:
      "Whenever any of the following fields changes, the new value will be scanned to see if it matches any of the keywords provided, and the automation will fire if it does. Matches are case-insensitive but must match spelling and punctuation exactly.",
    freeTextKeywordsLabel: "Free text keywords",
    freeTextType1: "Care profile notes",
    freeTextType2: "Session notes",
    freeTextType3: "Messages",
    frequencyLabel: "Frequency",
    goalIdLabel: "Goal",
    kindLabel: "Type",
    signedDocumentKindLabel: "Type of signed document",
    tagLabel: "Tag",
    taskClientVisibleLabel: "Member-facing Care Plan tasks?",
    taskKindLabel: "Type of task",
    trackerTypeLabel: "Tracker",
    userTypeLabel: "When the type of user who triggered the event is",
    webinarIdLabel: "Live event",
    worksheetTemplateIdLabel: "Worksheet"
  },
  carePlan: {
    descriptionLabel: "Summary of current situation",
    planLabel: "Grayce session schedule",
    stageLabel: "Stage",
    underEighteenOrDeceased: "Care recipient is under 18 or deceased."
  },
  carePlanActionItem: {
    assigneeLabel: "Owner",
    carePlanTaskTemplateId: "Member task library",
    draft: "Save",
    dueDateLabel: "Due date",
    focusAreaLabel: "Focus area",
    goalLabel: "Goal",
    includesLegalTracker: 'Includes button: "Begin legal document tracker"',
    includesMedTracker: 'Includes button: "Begin medication tracker"',
    includesProviderTracker: 'Includes button: "Add providers"',
    previewWorksheet: "Preview this worksheet",
    save: "Save",
    titleLabel: "Next step"
  },
  carePlanTaskTemplate: {
    bodyLabel: "Content",
    ctaLabel: "Assessment",
    focusAreasLabel: "Focus area(s)",
    tagsLabel: "Tags",
    titleLabel: "Title",
    userTypeLabel: "User type"
  },
  carePlanTaskTemplateFilters: {
    focusArea: "Focus area",
    searchTerm: "Search",
    userType: "Assignee type"
  },
  carePlansFilters: {
    consultReportStatusLabel: "Session report",
    endDateLabel: "End date",
    expertArchivedLabel: "Archived",
    expertLabel: "Primary Care Partner",
    startDateLabel: "Start date",
    statusLabel: "Status",
    timePeriodLabel: "Timeframe"
  },
  careProfile: {
    ageLabel: "How old is your loved one?",
    clientLocationLabel: "Where do you live?",
    clientLocationPlaceholder: "Postal code, neighborhood, or address",
    concernsLabel: "Where do you need the most help? (Select two)",
    concernsOtherLabel: "Other concern(s)",
    countryCodeLabel: "What country do they live in?",
    currentSupportLabel: "What type of help does your loved one receive?",
    currentSupportSublabel: "Select all that apply",
    guidanceTypeLabel: "Do you prefer to start with Care Partner support or a self-guided experience?",
    healthConcernsLabel: "Any health conditions of concern?",
    healthConcernsSublabel: "Select all that apply",
    interpretationNeededLabel: "Will you need language interpretation on live sessions?",
    languageCodeLabel: "What language will your loved one need support with?",
    locationLabel: "Where does your loved one live?",
    locationPlaceholder: "Postal code, neighborhood, or address",
    locationSublabel: "Our team uses this information to find local resources for you and your loved one.",
    nameLabel: "Who have you been helping? (name)",
    notesLabel: "Please share more about your situation and what would be most helpful to you.",
    otherConcernsLabel: "Other concerns",
    otherCurrentSupportLabel: "Other sources of support",
    otherHealthConcernsLabel: "Other health concerns",
    otherRelationshipLabel: "Other relationship",
    publish: "Publish",
    relationshipLabel: "What is {firstName}'s relationship to you?",
    relationshipOtherLabel: "Other",
    residenceTypeLabel: "Where does your loved one currently live?",
    saveAsDraft: "Save as draft",
    timezoneLabel: "What is your time zone?",
    timezoneSublabel: "Our team uses this information to show you events in your local time zone.",
    typesOfSupportNeededLabel: "Types of support from Grayce",
    urgencyLabel: "How soon do you need help?"
  },
  careProfileNew: {
    acuityLabel: "Acuity",
    addMember: "Add additional participant",
    addressLabel: "Location",
    ageLabel: "Age Range (deprecated)",
    carePhaseLabel: "Which best describes your care situation?",
    careTypesProvidedLabel: "Types of care provided",
    caregiverTypesLabel: "Care ecosystem",
    caregiverTypesOtherLabel: "Other caregivers",
    caregivingDurationLabel: "Length of time caregiving",
    caregivingImpactFinancialLabel: "Member's financial impact of caregiving",
    caregivingImpactPhysicalLabel: "Member's physical impact of caregiving",
    caregivingImpactWorkLabel: "Member's work impact of caregiving",
    caregivingTimePerWeekLabel: "Weekly hours dedicated to care (average)",
    cognitiveStatusesLabel: "Cognitive status",
    conservatorshipDetailsLabel: "Type of conservatorship",
    conservatorshipStatusLabel: "Conservatorship status",
    culturalConsiderationsLabel: "Cultural context",
    currentCareTransitionsLabel: "Current care transitions",
    currentCareTransitionsOtherLabel: "Other current care transitions",
    dateOfBirthLabel: "Date of birth",
    dateOfDeathLabel: "Date of death",
    dayToDaySupportNeededLabel: "Needs support with day-to-day care",
    deceasedGrievingMismatchWarning:
      "You indicated that the LO is deceased, but the care profile does not have the grieving use case. You may want to update the use case.",
    developmentalOrEducationalConcernsLabel: "Developmental or educational concerns",
    developmentalOrEducationalConcernsOtherLabel: "Other developmental or educational concerns",
    diagnosesLabel: "Medical diagnoses ({count})",
    distanceToLovedOneLabel: "Distance between LO and member",
    durableMedicalEquipmentLabel: "DME",
    emailLabel: "Email",
    emotionalConcernsLabel: "Emotional concerns",
    emotionalConcernsOtherLabel: "Other emotional concerns",
    employmentStatusLabel: "Employment status",
    everBeenCaregiverLabel: "Ever been a caregiver",
    exactAgeEnteredAtLabel: "Age entered at",
    exactAgeLabel: "Age",
    fallCountLabel: "Falls in the past 90 days",
    financialManagerLabel: "Financial manager",
    financialManagerOtherLabel: "Other financial manager",
    firstNameLabel: "First name",
    governmentBenefitsDetailsLabel: "Type of government benefit programs",
    guardianEmploymentStatusLabel: "Employment status of parents or legal guardians",
    guardianLegalDocumentsLabel: "Parent/guardian legal documents ({count})",
    guardianMaritalStatusLabel: "Marital status of parents or legal guardian",
    guardianTypeLabel: "Legal guardian",
    healthInsuranceDetailsLabel: "Plan name, ID number, etc.",
    healthInsuranceTypesLabel: "Health insurance",
    healthInsuranceTypesOtherLabel: "Other health insurance",
    hideExactAge: "Know the LO's birthdate? Enter it instead.",
    highRiskPregnancyLabel: "High-risk pregnancy",
    householdIncomeCurrencyCodeLabel: "Currency of income",
    householdIncomeLabel: "Annual Household income (specific)",
    householdIncomeLevelLabel: "Annual Household income (range)",
    immigrationStatusLabel: "Immigration status",
    incomeSourcesLabel: "Household income sources",
    insuranceDetailsLabel: "Policy type, number, company, etc.",
    insuranceTypesLabel: "Other insurance",
    insuranceTypesOtherLabel: "Other insurance free text",
    isDeceasedLabel: "Is the LO deceased?",
    isVeteranGuardianLabel: "Veteran status of parent or legal guardian",
    isVeteranLabel: "LO veteran status",
    isVeteranMemberLabel: "Member veteran status",
    isVeteranPartnerLabel: "Veteran status of spouse or domestic partner",
    isVeteranSelfLabel: "Veteran status",
    languageCodeLabel: "Language interpretation",
    lastNameLabel: "Last name",
    legalDocumentsLabel: "Legal documents ({count})",
    legalHelpNeededLabel: "Assistance required with legal docs",
    legallyDisabledLabel: "Legally disabled for benefits purposes",
    lifeThreateningConditionLabel: "Life-threatening condition or multiple medical conditions",
    longTermConditionLabel: "Long-term or lifelong condition",
    maritalStatusLabel: "Marital status",
    medicationManagementStatusLabel: "Medication management",
    medicationsAffordableLabel: "Medication is affordable",
    medicationsLabel: "Medications ({count})",
    memberId: "Member ID: ",
    memberPlaceIdLabel: "Location",
    nameLabel: "Name",
    numberLabel: "Phone number",
    painSeverityLabel: "Pain level",
    pastYearHospitalizationsLabel: "Number of hospitalizations in past 12 months",
    placeIdLabel: "Location",
    pregnancyDueDateLabel: "Due date",
    pronounsLabel: "Preferred gender pronoun(s)",
    pronounsOtherLabel: "Pronouns other",
    providerEmotionalStateLabel: "Emotional state",
    providerFeelsSupportedLabel: "Feels supported in caregiving",
    providerFinancialConcernDetailsLabel: "Explain concerns about finances",
    providerFinancialConcernsLabel: "Concerns about finances",
    providersLabel: "Providers ({count})",
    recentHospitalizationLabel: "Hospitalizations in the past 90 days",
    recentHospitalizationSynopsisLabel: "Synopsis of hospitalizations",
    recentIncidentLabel: "Accident or isolated incident in the last 60 days",
    relationshipLabel: "Relationship to LO",
    relationshipOtherLabel: "Other relationship",
    religionLabel: "Religion",
    religionOtherLabel: "Other religion",
    residenceTypeLabel: "Living environment",
    residenceTypeOtherLabel: "Other living environment",
    senseOfFulfillmentLabel: "LO sense of fulfillment",
    showExactAge: "Don't know LO's birthdate? Enter an age instead.",
    socialSatisfactionLabel: "LO satisfied with social connection",
    stabilitySupportsLabel: "Mobility assistance",
    stageBathingAndDressingLabel: "Bathing and dressing help required",
    stageContinenceLabel: "Continence help required",
    stageEatingLabel: "Eating help required",
    stageHousekeepingLabel: "Housekeeping help required",
    stageLabel: "Care stage",
    stageMealPrepLabel: "Meal prep help required",
    stageShoppingLabel: "Shopping help required",
    stageToiletingLabel: "Toileting help required",
    stageTransportationLabel: "Transportation help required",
    supportingAdultChildrenLabel: "Adult children supported",
    supportingGrandparentsLabel: "Grandparents supported",
    supportingMinorChildrenLabel: "Minor children supported",
    supportingOtherLabel: "Others supported",
    supportingParentsLabel: "Parents supported",
    typesOfSupportNeededLabel: "Types of support from Grayce",
    useCaseLabel: "Use case",
    vaConnectedLabel: "Connected to VA services"
  },
  client: {
    placeIdLabel: "My address is:",
    timezoneLabel: "My timezone is:"
  },
  clientAccountUpdate: {
    clientLocationLabel: "Residence",
    countryCodeLabel: "What country do you live in?",
    emailLabel: "Email",
    emailPreferencesLabel: "Messages with your Care Partner",
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    locationLabel: "What city do you live in?",
    numberLabel: "Phone number",
    timezoneLabel: "Time zone"
  },
  clientPasswordUpdate: {
    passwordConfirmationLabel: "Password confirmation",
    passwordCurrentLabel: "Current password",
    passwordLabel: "New password",
    success: "Password updated successfully"
  },
  consult: {
    addInvites: "Add invites",
    addOtherInvites: "Add other invites",
    availabilityDateLabel: "Date",
    carePartnerTimeLabel: "Care Partner",
    contactMethodLabel: "Contact via",
    expertOnly: "This availability is only visible to Care Partners",
    expertPhoneNumberLabel: "Member's phone number",
    inviteClients: "Invite additional members to this session",
    inviteOthers: "Invite others",
    invitedCareTeamInviteIdsLabel: "Invitee(s):",
    invitedCareTeamInviteIdsLabelOther: "Other Invitee(s):",
    invitedCareTeamInviteIdsSublabel:
      "Invite others to share your Grayce benefit and be reminded about this session. They will be reminded once they have accepted the invite.",
    invitedClientIdsLabel: "Invitee(s):",
    inviteeSelf: "{name} (self)",
    languageCodeLabel: "Language interpretation",
    memberTimeLabel: " / Member time zone",
    mmddyy: "MM/DD/YY",
    newJourneyIdLabel: "Move session to",
    noAvailability: "There are no care partners available for this date and session length.",
    notesLabel: "What do you want to discuss?",
    pickADay: "Pick a session length and date to see availabilities.",
    timeUserLabel: "{time} ({name})"
  },
  consultCancel: {
    noteLabel: "Note (For Care Partner view only)"
  },
  consultReassign: {
    newExpertLabel: "New Care Partner"
  },
  consultReport: {
    clientAttendeesLabel: "Who attended this session?",
    durationLabel: "Duration",
    expertAttendeeIdLabel: "Care Partner attendee",
    formatLabel: "Format",
    nextConsultLabel: "Next session",
    otherLabel: "Other attendee(s)",
    stageLabel: "Care journey stage",
    summaryLabel: "Summary"
  },
  contentConditions: {
    answer: "Answer",
    concern: "Concern",
    criteria: "Criteria",
    needFromGrayce: "Needs from Grayce",
    providerEmotionalState: "Provider emotional state",
    providerFeelsSupported: "Provider feels supported",
    question: "Question",
    relationship: "Relationship",
    source: "Source",
    stage: "Stage"
  },
  defaults: {
    placeholder: {
      textarea: "Type here..."
    }
  },
  diagnosis: {
    conditionLabel: "Condition",
    conditionOtherLabel: "Other condition",
    controlLabel: "Control",
    dateLabel: "Date of diagnosis",
    medicalConditionIdLabel: "Condition",
    severityLabel: "Severity"
  },
  directUploadFileInput: {
    attachFiles: "Attach files",
    attachMoreFiles: "Add more files",
    selectAFile: "Select a file"
  },
  eligibility: {
    employeeIdLabel: "Eligibility file employee",
    employerIdLabel: "Employer",
    expertIdLabel: "Care Partner",
    notesLabel: "Notes"
  },
  eligibilityFile: {
    blobIdLabel: "File"
  },
  employer: {
    addCountry: "Add a country",
    addCountryButton: "Add country",
    addEmailDomain: "Add a domain",
    addEmailDomainButton: "Add domain",
    additionalPackageDetailsLabel: "Additional package details",
    addressLabel: "Address",
    carePlanningPackageLabel: "Has care planning package",
    contractBeginDateLabel: "Contract begin date",
    contractEndDateLabel: "Contract end date",
    countLabel: "Employee count",
    cultureLabel: "Other context on employer culture",
    eligibilityMethodLabel: "Eligibility method",
    emailDomainLabel: "Domain",
    emailDomainsLabel: "Email domains",
    employeeCount: ": {count}",
    employeeCountByCountriesLabel: "Employees by country",
    employeeCountLabel: "Employee count",
    employeeCountryLabel: "Country",
    employeesPurchasedLabel: "Employees purchased",
    enrollmentCodeLabel: "Enrollment code",
    expectedUtilizationLabel: "Expected utilization",
    fileShareFrequencyLabel: "File share frequency",
    fileShareMethodLabel: "File share method",
    fileSubmissionTimeFrameLabel: "File submission timeframe (ex: 1st of each month)",
    globalPopulationLabel: "Global population",
    hideBenefitsLabel: "Hide Marketplace benefits",
    ignoreFromReportingLabel: "Ignore this employer from dashboards and reports",
    industryLabel: "Industry",
    minutesLabel: "Minutes",
    nameLabel: "Name",
    nonRenewedLabel:
      "Non renewed (The employer has elected not to continue their contract. Once their current contract expires, users will no longer be able to sign up.)",
    packageCostLabel: "Package cost",
    paymentTermsLabel: "Payment terms",
    platformFeeLabel: "Platform fee",
    unpaidLabel: "Unpaid (select this for employers that are used for testing, demos, freebies, or Grayce employees)",
    updateCountryCountButton: "Update count",
    websiteLabel: "Website"
  },
  employerBenefit: {
    addLink: "Add a link",
    attachmentLabel: "PDF attachment(s)",
    categoriesLabel: "Categories",
    categoryOtherLabel: "Other",
    costLabel: "Cost",
    countries: "Countries",
    countriesLabel: "Countries",
    countriesSubLabel: "Search to add a country or geography",
    descriptionLabel: "Description & details",
    emailLabel: "Email label",
    emailLinkLabel: "Email link (website or email)",
    hoursOfOperationLabel: "Hours of operation",
    lastUpdatedLabel: "Last updated",
    linkLabel: "Link",
    linksLabel: "Website URL(s)",
    phoneNumberLabel: "Phone number label",
    phoneNumberLinkLabel: "Phone number link (website or phone number)",
    photoBlobIdLabel: "Upload image or logo",
    vendorNameLabel: "Vendor name"
  },
  employerContract: {
    additionalPackageDetailsLabel: "Additional package details",
    billableCalculationLabel: "Billable calculation method",
    billableCalculationSubLabel:
      "Changing the billable calculation for a contract that is in effect will only update members who sign up after the change is made. If you need to retroactively change this setting, please contact the dev team.",
    billableCapLabel:
      "Billable member cap (the maximum number of employees allowed to become billable in a contract year)",
    employeesPurchasedLabel: "Number of employees purchased",
    enrollmentCodeLabel: "Enrollment code",
    explanation:
      "Contracts may not overlap with other contracts. If you are attempting to add to an existing contract, you can either modify that contract or add credits to one of its refresh periods.",
    hasCarePlanningInstructions:
      "For contracts that have already begun, removing the care planning package is not allowed unless also changing to unlimited time. Adding the package will retroactively update all existing member grants under the contract to add the new minutes.",
    hasCarePlanningLabel: "Has care planning package",
    hasUnlimitedMinutesInstructions:
      "For contracts that have already begun, removing unlimited minutes is not allowed. Adding the package will retroactively update all existing member grants under the contract to make them unlimited.",
    hasUnlimitedMinutesLabel: "Has unlimited time",
    minutesInstructions:
      "For contracts that have already begun, reducing the number of minutes is not allowed unless changing to unlimited. Increasing the number of minutes will retroactively update all existing member grants under the contract to add the new minutes.",
    minutesLabel: "Minutes",
    packageCostLabel: "Package cost",
    paymentTermsLabel: "Payment terms",
    platformFeeLabel: "Platform fee",
    startDateLabel: "Start date",
    yearsLabel: "Contract duration (in years)"
  },
  employerContractModification: {
    additionalPackageDetailsLabel: "Additional package details",
    billableCalculationLabel: "Billable calculation method",
    billableCalculationSubLabel:
      "Changing the billable calculation for a contract that is in effect will only update members who sign up after the change is made. If you need to retroactively change this setting, please contact the dev team.",
    billableCapLabel:
      "Billable member cap (the maximum number of employees allowed to become billable in a contract year)",
    changesBillableCalculationLabel: "Update the billable calculation?",
    changesBillableCapLabel: "Update the billable cap?",
    changesContractDetailsLabel: "Update the pricing and other details of the contract?",
    changesEmployeesPurchasedLabel: "Update the number of employee seats available in the contract?",
    changesPackageLabel: "Update the package received by employees?",
    employeesPurchasedLabel: "Number of employees purchased",
    enrollmentCodeLabel: "Enrollment code",
    hasCarePlanningLabel: "Has care planning package",
    hasUnlimitedMinutesLabel: "Has unlimited time",
    howThisWorks1:
      "This form allows you to make modifications to contracts. You will need to select a start date for the change, the parts of the contract you want to modify, and values for the individual fields.",
    howThisWorks2:
      "If the start date you select is in the past, this is a retroactive change. For these types of changes that update the member package or the billable calculation, any members who signed up or received updates during the period of the new change will have their packages updated to reflect the modification. You can add package resources when making a retroactive change, but you cannot remove them. If you need to reduce the package for a contract, you will need to create a modification starting in the future.",
    howThisWorks3:
      "Once you create a modification, you will only be able to edit it if it starts in the future, so be very careful about setting things correctly here. You will never be able to edit the start date of the modification, but for future ones you can delete them and create a new one.",
    minutesLabel: "Minutes",
    packageCostLabel: "Package cost",
    paymentTermsLabel: "Payment terms",
    platformFeeLabel: "Platform fee",
    retroactiveWarning:
      "You are making a retroactive change to the contract that changes the billable calculation or member package. If you do this, it will update all member packages that have been received in the time that this modification is active. Please take extra care to make sure this is what you intend to do.",
    startDateLabel: "Start date",
    yearsLabel: "Contract duration (in years)"
  },
  employerQbr: {
    endDateLabel: "End date",
    startDateLabel: "Start date"
  },
  expert: {
    ageSpecialtiesLabel: "Specialties - age",
    bioLabel: "Bio",
    conditionSpecialtiesLabel: "Specialties - conditions",
    coversAsiaLabel: "Covers Asian timezones",
    coversEuropeLabel: "Covers European timezones",
    coversUsEastLabel: "Covers Eastern US timezones",
    coversUsWestLabel: "Covers Western US timezones",
    emailLabel: "Email",
    firstNameLabel: "First name",
    hidden: "Hidden from members",
    journeyAssignmentWeightLabel: "Assignment weight (a percent between 0 and 100)",
    lastNameLabel: "Last name",
    maxConsultsPerDayLabel: "Maximum number of sessions per day",
    notHidden: "Member-facing",
    otherSpecialtiesLabel: "Specialties - other",
    photoBlobIdLabel: "Photo",
    timezoneLabel: "Timezone",
    zoomIdLabel: "Zoom ID"
  },
  expertArchiveJourney: {
    clientName: "Member: {name}",
    journeyName: "Journey: {name}",
    otherLabel: "Other reason",
    reasonLabel: "Reason to archive"
  },
  expertAssignment: {
    client: "Member",
    currentExpert: "Current Care Partner",
    expertIdLabel: "New Care Partner",
    journeyIdsLabel: "Journeys",
    lovedOne: "Loved one",
    multipleJourneys:
      "This member has multiple journeys, would you like to reassign all journeys to a new Care Partner?",
    reassign: "Reassign"
  },
  expertConsultsFilters: {
    consultReportStatusLabel: "Session report status",
    employerLabel: "Employer",
    endTimeLabel: "End date",
    expertLabel: "Care Partner",
    needsInterpretationLabel: "Language interpretation",
    startTimeLabel: "Start date",
    timePeriodLabel: "Time period"
  },
  expertJourneysFilters: {
    employerLabel: "Employer",
    expertArchivedLabel: "Archived",
    expertOwnerLabel: "Care Partner owner",
    expertRoleLabel: "{name}'s role",
    me: "Me ({name})",
    myRoleLabel: "My role",
    searchLabel: "Search",
    statusLabel: "Status"
  },
  fileResource: {
    blobIdLabel: "Select a file to upload",
    create: "Create file",
    folderIdLabel: "Upload to a folder",
    titleLabel: "File name",
    updateBlobIdLabel: "Replace file",
    updateFolderIdLabel: "Move to a different folder",
    yourDocuments: "Your documents"
  },
  findAnotherTime: {
    contentLabel: "What days or times work best for you?"
  },
  folder: {
    create: "Create folder",
    titleLabel: "Folder name"
  },
  goalTemplate: {
    actionItemLibrary: "Task library",
    addContent: "Add content",
    addTask: "Add task",
    addTasks: "Add tasks",
    addTool: "Add a tool",
    addTrigger: "Add trigger",
    addcontent: "Add content",
    addexpert_tasks: "Add Care Partner task",
    addmember_tasks: "Add member task",
    addtools: "Add tool",
    addtriggers: "Add trigger",
    content: "Content",
    countriesLabel: "Countries",
    countriesSubLabel: "Search to add a country or geography",
    create: "Save goal",
    descriptionLabel: "Description",
    expertTasks: "Care Partner tasks",
    focusAreasLabel: "Focus area(s)",
    memberTask: "Member tasks",
    selectForm: "Select form",
    selectTracker: "Select tracker",
    titleLabel: "Title",
    toolKindLabel: "Type",
    tools: "Tools",
    triggers: "Trigger(s)",
    worksheetLibrary: "Worksheet library"
  },
  goalTemplateFilters: {
    focusAreaLabel: "Focus area",
    kindLabel: "Type",
    searchTermLabel: "Search",
    usedInJourneyLabel: "Used in this journey"
  },
  internalNoteTemplate: {
    contentLabel: "Content",
    titleLabel: "Title"
  },
  inviteAccountMember: {
    addAnother: "Add another",
    emailLabel: "Email",
    messageLabel: "Add a personal message (optional)",
    send: "Send invite",
    sendAndComplete: "Send invites and complete",
    shorterTip:
      "Tip: Here are some suggestions for who to invite: your sibling(s), your spouse or partner, a friend that is always there for you, a friend or family member who lives close to your loved one, someone who is very organized, or someone you can always rely on.",
    tip: "Invite others to share your Grayce benefit. Here are some suggestions for who to invite: your sibling(s), your spouse or partner, a friend that is always there for you, a friend or family member who lives close to your loved one, someone who is very organized, or someone you can always rely on.",
    title: "Invite someone to this care journey (optional)",
    withdraw: "Withdraw invitation"
  },
  journey: {
    archivedTagsLabel: "Archived tags",
    tagsLabel: "Active tags"
  },
  journeyTagFilters: {
    employerLabel: "Employer",
    searchLabel: "Search"
  },
  journeyTaggingFilters: {
    employerLabel: "Employer",
    searchLabel: "Search",
    statuses: {
      active: "Active",
      all: "All",
      archived: "Archived"
    }
  },
  journeyTagsMerge: {
    mergeTagsLabel: "Tag(s) to merge",
    save: "Merge tags",
    tagLabel: "Replace with this tag"
  },
  journeysSearch: {
    anyEmployer: "Any employer",
    onlyMyJourneys: "Only my journeys"
  },
  language: {
    clientLanguageCodeLabel: "What language will you need support with?",
    lovedOneLanguageCodeLabel: "What language will your loved one need support with?",
    needsLanguageInterpretationLabel: "Do you need language interpretation?",
    typeLabel: "Who will need language interpretation?"
  },
  legalDocument: {
    attachmentLabel: "Upload (optional)",
    kindLabel: "Type",
    notesLabel: "Notes",
    statusLabel: "Status",
    storageLocationLabel: "Where it's stored",
    updateDateLabel: "Date updated"
  },
  login: {
    biometrics: {
      faceGeneric: {
        androidSubtitle: " ",
        androidTitle: "Login to Grayce using your image",
        cancelTitle: "Skip face login",
        iosFallbackTitle: " "
      },
      faceId: {
        androidSubtitle: " ",
        androidTitle: " ",
        cancelTitle: "Skip FaceID",
        iosFallbackTitle: "Try again"
      },
      fingerprintGeneric: {
        androidSubtitle: " ",
        androidTitle: "Login to Grayce using your fingerprint",
        cancelTitle: "Skip fingerprint login",
        iosFallbackTitle: " "
      },
      irisGeneric: {
        androidSubtitle: " ",
        androidTitle: "Login to Grayce using your iris scan",
        cancelTitle: "Skip iris scan login",
        iosFallbackTitle: " "
      },
      reason: "Login to Grayce without having to type your email and password",
      touchId: {
        androidSubtitle: " ",
        androidTitle: " ",
        cancelTitle: "Skip TouchID",
        iosFallbackTitle: "Try again"
      }
    },
    emailLabel: "Email",
    forgotPassword: "Forgot password?",
    passwordLabel: "Password"
  },
  medication: {
    dosageLabel: "Dosage and frequency (e.g. 5 mg, twice daily)",
    drugIdLabel: "Medication name",
    formatLabel: "Form",
    formatOtherLabel: "Other format",
    instructionsLabel: "Instructions (e.g. take with food)",
    kindLabel: "Medication type",
    nameLabel: "Medication name",
    notesLabel: "Additional notes",
    otherNameLabel: "Other name",
    paymentTypeLabel: "Payment type",
    pharmacyLabel: "Pharmacy",
    prescriberLabel: "Prescribing clinician",
    prescriptionNumberLabel: "Rx # (if applicable)",
    reasonForTakingLabel: "Indication (reason for taking)",
    refillFrequencyLabel: "Refill frequency"
  },
  messageCreate: {
    bodyPlaceholder: {
      desktop: "Type your message",
      mobile: "Aa"
    },
    ctaLabel: "Include button to schedule a session",
    resourceInfo: 'Regarding {category} resource, "{title}"',
    resourceInfoNoCategory: 'Regarding resource, "{title}"',
    worksheetInfo: 'Regarding worksheet, "{title}"'
  },
  messageTemplate: {
    categoryLabel: "Category",
    contentLabel: "Message content",
    titleLabel: "Template name"
  },
  messageTemplateCategory: {
    titleLabel: "Category name"
  },
  messageTemplateFilters: {
    categoryLabel: "Category"
  },
  myStoryLead: {
    messageLabel: "Tell us about your story",
    messagePlaceholder: "Ex. I am caring for my mother with Dementia…"
  },
  oidcProvider: {
    employerIdLabel: "Employer",
    explanation:
      "This form is for managing SSO providers using OpenID Connect. It will need to be connected to an employer. The name field will be used in the authentication URL and should be the name of the employer in all lowercase. The other fields should be provided by the employer.",
    hostLabel: "Host",
    identifierLabel: "Client id / identifier",
    nameLabel: "Name",
    secretLabel: "Client secret"
  },
  onboarding: {
    basics: {
      ageLabel: "Their age is:",
      ageLabelSelf: "My age is:",
      nameLabelRelationship: "My {relationship} name is:",
      nameLabelRelationshipOther: "My {relationship}'s name is:",
      nameLabelUnknownRelationship: "My loved one's name is:"
    },
    guidanceType: {
      expertGuidedDescription:
        "We also encourage you to explore our library of articles, videos, webinars, and the Grayce Community.",
      meetYourExpert: "Meet your Grayce Care Partner!",
      selfGuidedDescription:
        "Great, we'll start by showing you the content library of articles, videos, and webinars. You can book a session with your dedicated Care Partner at any time through the session tab for one on one support."
    },
    languageInterpretation: {
      interpretationNeededLabel:
        "We support families across the globe and provide language interpretation at no cost to you.",
      languageCodeLabel: "What is your preferred language?"
    },
    location: {
      careProfileLocationLabelRelationship: "My {relationship} address is:",
      careProfileLocationLabelRelationshipOther: "My {relationship}'s address is:",
      careProfileLocationLabelUnknownRelationship: "My loved one's address is:",
      clientLocationLabel: "My address is:",
      timezoneLabel: "My timezone is:"
    },
    relationship: {
      iAmA: {
        child: "I am their parent",
        child_of_friend_or_relative: "I am a friend or relative to their parent",
        domestic_partner: "I am their domestic partner",
        foster_child: "I am their foster parent",
        friend: "I am their friend",
        grandchild: "I am their grandparent",
        grandparent: "I am their grandchild",
        myself: "I am here for myself",
        niece_or_nephew: "I am their aunt or uncle",
        parent: "I am their child",
        parent_in_law: "I am their child-in-law",
        relative: "I am their relative",
        sibling: "I am their sibling",
        spouse: "I am their spouse"
      }
    },
    typesOfSupportNeeded: {
      typesOfSupportNeededLabel: "Select all that apply"
    }
  },
  outOfOffice: {
    alerts: {
      consultConflicts: "{count, plural, one {# session conflict} other {# session conflicts}}",
      heading: "Creating this event would result in the following issues:",
      noAsiaCoverage: "No coverage for Asia",
      noEuropeCoverage: "No coverage for Europe",
      noExperts: "No Care Partners available for assignments"
    },
    backupExpertLabel: "Back up Care Partner",
    endTimeLabel: "End time (in the Care Partner's timezone)",
    expertLabel: "Care Partner out of office",
    startTimeLabel: "Start time (in the Care Partner's timezone)"
  },
  packageGrant: {
    hoursLabel: "Hours"
  },
  passwordReset: {
    emailLabel: "Email",
    submit: "Send link"
  },
  passwordUpdate: {
    passwordConfirmationLabel: "Confirm password",
    passwordHint:
      "Please enter a password with between 8 and 72 characters, 1 capital letter, 1 lowercase letter, and 1 number.",
    passwordLabel: "Password",
    save: "Reset password"
  },
  peerSupportRequest: {
    consentToContactLabel: "I give consent for Grayce to share my email address with my paired peer contact.",
    contactInfoLabel: "Email address",
    messageLabel: "What are you looking for in a 1 on 1 peer connection?",
    messagePlaceholder: "Ex. I want to be paired with someone who is also supporting a parent"
  },
  provider: {
    addressLabel: "Address (include suite), city, state, zip",
    faxLabel: "Fax",
    kindLabel: "Provider type",
    nameLabel: "Name",
    notesLabel: "Notes",
    otherSpecialtyLabel: "Other specialty",
    patientPortalLabel: "Patient portal (URL)",
    phoneLabel: "Phone",
    practiceNameLabel: "Name of practice (if applicable)",
    specialtyLabel: "Specialty",
    typeOfCareProvider: "Type of care provider",
    websiteLabel: "Website (URL)"
  },
  refreshPeriod: {
    enrollmentCodeLabel: "Enrollment code"
  },
  refreshPeriodCredit: {
    employeesLabel: "Added seats"
  },
  registration: {
    dateOfBirthFormat: "mm/dd/yyyy",
    dateOfBirthLabel: "Date of birth",
    disclosureAgreementLabel: {
      contactEmail: "dpo@withgrayce.com",
      copy: "I authorize Grayce to disclose my information, including healthcare and other medical information, to healthcare provider(s) and those third-party entities listed in the Privacy Policy for the purposes outlined in therein. This Authorization will remain in effect until 2050, or until you revoke such authorization in writing, after which Grayce will no longer be authorized to disclose your medical information until and unless you execute another Authorization. You have the right to receive a copy of this authorization upon request. Should you wish to receive a copy of this authorization, please send a request to"
    },
    emailInstructions:
      "We recommend using your personal email address so that you can continue to access Grayce if your employment changes.",
    emailLabel: "Personal email",
    employerLabel: "Employer",
    enrollmentCodeInstructions: "If your employer provided a code, enter it here.",
    enrollmentCodeLabel: "Enrollment code",
    firstNameLabel: "First name",
    lastNameLabel: "Last name",
    locationLabel: "Location",
    locationSubLabel:
      "In order to provide virtual session times and custom resources, we need to know the location where you live.",
    marketingAgreementLabel:
      "Yes, please keep me updated on the latest services, information, and personally relevant offers. You can change your preferences at any time in your account settings.",
    passwordConfirmationLabel: "Confirm password",
    passwordHint:
      "Please enter a password with between 8 and 72 characters, 1 capital letter, 1 lowercase letter, and 1 number.",
    passwordLabel: "Password",
    purchase: {
      carePlanPackage: "Care planning package",
      suggested: "Suggested"
    },
    termsAndConditionsLabel: {
      and: "and",
      iAgree: "I agree to the",
      privacyPolicy: "Privacy Policy",
      termsOfUse: "Terms of Use"
    },
    timezoneLabel: "Timezone",
    workEmailInstructions: "Your work email will be used to verify your eligibility for Grayce.",
    workEmailLabel: "Work email"
  },
  relationship: {
    label: "What is {firstName}'s relationship to you?",
    labelList: "I am:",
    relationshipOtherLabel: "Other relationship",
    title: "Edit your relationship"
  },
  resource: {
    basicInformation: "Basic information",
    costLabel: "Cost",
    descriptionLabel: "Description",
    draft: "Save",
    featuresAndBenefits: "Features and benefits",
    focusArea: "Focus area",
    hotlineIdLabel: "Hotline",
    linkTitleLabel: "Link title",
    linkUrlLabel: "Url",
    phoneNumberLabel: "Phone number",
    publish: "Publish resource",
    titleLabel: "Name"
  },
  resourceFeature: {
    addResourceFeature: "Add a feature to compare",
    kindLabel: "Type",
    kinds: {
      boolean: "Yes / No",
      text: "Text"
    },
    nameLabel: "Feature name",
    resourceFeatureTemplateIdLabel: "Criteria template"
  },
  richTextArea: {
    controls: {
      attachments: "Attachment",
      inAppLinks: "In-app link",
      internalNoteTemplates: "Insert note template",
      mergeTags: "Merge tag",
      templates: "Insert template"
    },
    linkTextLabel: "Text",
    linkUrlLabel: "URL"
  },
  roleForm: {
    addCareRecipient: "Add care recipient",
    careRecipientLabel: "Your loved one"
  },
  signedDocumentFax: {
    documentForLabel: "Consent form for",
    faxForLabel: "Facility name",
    faxNumberLabel: "Fax number",
    internationalNumbers: "For international numbers, replace the country code with {this}",
    noteLabel: "Note to facility",
    sendFax: "Send fax"
  },
  simpleLink: {
    addAnother: "Add another",
    labelLabel: "Label for the link",
    valueLabel: "Value for the link"
  },
  supportingExperts: {
    addToJourney: "Add to journey",
    grayceExpert: "Grayce Care Partner"
  },
  task: {
    addTask: "Add task",
    assigneeLabel: "Assignee",
    attachmentLabel: "Attachments",
    cancel: "Cancel",
    carePlanTaskTemplateId: "Care Partner task library",
    categoryOptionLabel: "Category",
    categoryOtherLabel: "Other",
    clientIdLabel: "Member",
    clientVisibleLabel: "Member view",
    draft: "Save",
    dueTimeLabel: "Due date",
    editTask: "Edit task",
    expectedStartDateLabel: "Expected start date",
    expertIdLabel: "Owner",
    expertLabel: "Care Partner journeys",
    focusAreaLabel: "Focus area",
    journeyIdLabel: "Member name",
    notesLabel: "Notes",
    publish: "Publish task",
    statusLabel: "Status",
    tagsLabel: "Tags",
    titleLabel: "Title"
  },
  taskCancel: {
    areYouSure: "Are you sure you would like to cancel this service?",
    cancelService: "Cancel service",
    keepService: "Keep service",
    noteLabel: "Reason to remove service"
  },
  taskResourceFeatures: {
    explanation:
      "Add features to compare resources to one another. Any features that you add here will be available to edit for all resources added to this task."
  },
  timeLog: {
    activityBillableLabel: "Billable?",
    activityKindIdLabel: "Activity",
    carePlanPackageLabel: "Care Plan package",
    dateLabel: "Date",
    journeyIdLabel: "Journey",
    minuteBlockInstructions: "Please enter time in 15 minute increments",
    minutesInstructions: "To add hours to this member's balance, enter a negative number of minutes",
    minutesLabel: "Actual time",
    notesLabel: "Notes",
    scheduledMinutesLabel: "Scheduled time",
    supportCategoryLabel: "Support category",
    supportKindIdLabel: "Support"
  },
  timeLogFilters: {
    billableLabel: "Billable",
    expertLabel: "Care Partner"
  },
  timePeriod: {
    endTimeLabel: "End date",
    periodOptions: {
      all_time: "All time",
      custom: "Custom",
      ninety_days: "Last 90 days",
      seven_days: "Last 7 days",
      sixty_days: "Last 60 days",
      thirty_days: "Last 30 days"
    },
    startTimeLabel: "Start date",
    timePeriodLabel: "Time period"
  },
  universalSearch: {
    hitType: {
      CARE_PLAN_ACTION_ITEM_TITLE: "{journeyName} / Member tasks",
      CARE_PROFILE_EMOTIONAL_CONCERNS: "{journeyName} / Emotional concerns",
      CARE_PROFILE_LOCATION: "{journeyName} / Loved one location",
      CARE_PROFILE_NAME: "{journeyName} / Loved one name",
      CARE_TEAM_INVITE_EMAIL_ADDRESS: "{journeyName} / Care team invite email address",
      CHAT_MESSAGE_BODY: "{journeyName} / Message",
      CLIENT_EMAIL_ADDRESS: "{journeyName} / Client email address",
      CLIENT_ID: "{journeyName} / Member ID",
      CLIENT_LOCATION: "{journeyName} / Member location",
      CLIENT_NAME: "{journeyName} / Member name",
      FILE_RESOURCE_TITLE: "{journeyName} / Drive",
      GOAL_TASK_RESOURCE_DESCRIPTION: "{journeyName} / Resource description",
      GOAL_TASK_RESOURCE_FEATURE_VALUES_TEXT_VALUE: "{journeyName} / Resource feature",
      GOAL_TASK_RESOURCE_TITLE: "{journeyName} / Resource title",
      GOAL_TASK_TITLE: "{journeyName} / Care Partner task title",
      GOAL_TITLE: "{journeyName} / Goal title",
      INTERNAL_NOTE_BODY: "{journeyName} / Internal note body",
      INTERNAL_NOTE_TITLE: "{journeyName} / Internal note title",
      MEDICATION_NAME: "{journeyName} / Medication",
      PROVIDER_NAME: "{journeyName} / Provider",
      TAG_LIST: "{journeyName} / Tag"
    }
  },
  webinarFilters: {
    occurrenceId: "Which occurrence of the webinar?"
  },
  whatsThis: "What's this?",
  worksheet: {
    descriptionLabel: "Description",
    folderIdLabel: "Move to another folder",
    titleLabel: "Worksheet name"
  },
  worksheetTemplate: {
    descriptionLabel: "Description",
    focusAreasLabel: "Focus area(s)",
    titleLabel: "Title"
  },
  worksheetTemplateFilters: {
    archivedLabel: "Archived",
    focusAreaLabel: "Focus area",
    searchTermLabel: "Search"
  },
  worksheetTemplateItem: {
    acceptsCheckboxLabel: "Accepts checkbox",
    acceptsDetailsLabel: "Accepts details",
    titleLabel: "Title"
  }
};

export default forms;
