/* eslint-disable @typescript-eslint/naming-convention */
const accessibility = {
  altTags: {
    activateFeatureDogWash: "Family washing a dog",
    activateFeatureFamily: "Family eating together",
    activateHipaa: "Hipaa compliance",
    activateStars: "Member rating",
    activateTopics: {
      adult: "Adult",
      aging: "Aging",
      child: "Children",
      grieving: "Grieving",
      pregnancy: "Pregnancy"
    },
    activeElders: "People being active, lifting weights and playing badminton",
    appleAppStore: "Apple App Store badge",
    article: "Current article image",
    articleImage: "Article image",
    avatar: "Avatar",
    bench: "Two people talking on a bench",
    calendar: "Calendar with sessions due today",
    chess: "Two people sitting playing chess",
    clipboard: "Clipboard with worksheet",
    communityFamily: "Family looking at tablet together",
    consultBooked: "Two people dancing",
    contentLock: "Person closing a lock",
    cpInProgress: "Two people dancing",
    dancing: "Two people dancing",
    dancingSeniors: "Two seniors dancing",
    drinkingCoffee: "Two people drinking coffee",
    earth: "Earth",
    employerBenefit: "Employer benefit image",
    employerList: "Person with list",
    entrepeneurLogo: "Entrepeneur logo",
    envelopeHeart: "An envelope with a message stamped with a heart",
    expertPhoto: "Your Care Partner's photo",
    file: "A file",
    financial: "Woman placing a coin in a jar",
    focusAreaDividerImg: "Divider",
    folder: "Folder",
    forbesLogo: "Forbes logo",
    functional: "Person sitting in a chair, reading a book",
    gather: "Gather",
    givingGift: "Giving gifts",
    google: "Google",
    googlePlayStore: "Google Play Store badge",
    grayceEventsCalendar: "One woman talks on a phone, while another uses a large pencil to markup a giant calendar",
    guide: "Simplify and guide",
    happyFamily: "A family holding hands",
    hipaa: "HIPAA compliant",
    info: "Click here for more information",
    ladyWritingMessage: "A lady writing a message",
    legal: "Two people shaking hands",
    mail: "Letter envelope for new messages",
    manOnComputer: "Man sitting at a desk on computer",
    manReadingRadio: "Man reading a newspaper and listening to a radio",
    manWithGiantMagnifyingGlass: "Man with a giant magnifying glass",
    managerGroup: "Group of managers planning",
    map: "Two people exploring, looking at a map",
    mapBusSelfie: "People looking at a map, waiting at a bus stop and taking a selfie",
    mapPin: "A map pin",
    medical: "Person pushing someone in a wheelchair",
    medicationGroup: "Different pills and pill bottles",
    medicationsListComplete: "Bottle of medications, and a clipboard with medical details",
    medicationsListIncomplete: "Bottle of medications, and a clipboard with medical details",
    momDaughterMaze: "A mom and her daughter working on a maze together",
    newClients: "New member waving hello",
    noConsult: "Woman at a desk on a computer",
    noHours: "A woman pushing a shopping cart",
    noTasks: "A worksheet",
    oneHour: "One hour",
    peopleCoffeeLight: "Two people drinking coffee",
    peopleHoldingHearts: "People holding hearts",
    personComputer: "Person sitting, looking at a computer",
    phone: "A phone",
    poweredByGoogle: "Powered by google",
    providersListComplete: "A notepad and list with provider details completed",
    providersListIncomplete: "A notepad and list with provider details incompleted",
    purpleLogo: "Grayce purple logo",
    research: "Research and recommend",
    safetyReport: "A woman, a shield with a plus and a clipboard",
    saveTime: "Save time",
    searchSelectItem: "Search select item",
    shareStory: "Two people talking and holding books",
    signConsentForm: "Sign consent form",
    sixtyMinutes: "One hour",
    social: "Two people sitting playing chess",
    stages: {
      bereavement: "Person hugging a heart",
      crisis: "Person on a stretcher being pushed",
      dependent: "Person pushing someone in a wheelchair",
      end_of_life: "Person leaning over a loved one on a bed",
      independent: "Woman on chair knitting",
      interdependent: "Two people sitting playing chess"
    },
    stripOfActivities: "Man looking at a newspaper, a woman knitting, people playing chess, person painting",
    thirtyMinutes: "Thirty minutes",
    timeLogo: "Time logo",
    twoPeopleCoffee: "Two people sitting at a table drinking coffee",
    under_eighteen: "Two people with a child",
    usNewsLogo: "US News logo",
    wellbeing: "Woman sitting drinking coffee",
    whiteLogo: "Grayce white logo",
    womanAtDesk: "Woman at a desk on a computer",
    womanChairComputer: "Woman sitting on a chair, looking at a computer",
    womanPlan: "Woman sketching and planning on a piece of paper",
    womanWithCoffee: "Woman sitting drinking coffee",
    womensHealthLogo: "Women's Health logo",
    yourCareGroup: "A care group chatting"
  },
  ariaLabels: {
    actions: "Actions",
    add: "Add",
    archive: "Archive",
    attachFiles: "Attach files",
    back: "Back",
    bold: "Bold",
    bulletList: "Bulleted list",
    cancel: "Cancel",
    check: "Checkmark",
    checkbox: "Checkbox",
    clear: "Clear",
    close: "Close",
    completeTemplate: "Complete template",
    copy: "Copy",
    delete: "Delete",
    deleteInvitation: "Delete invitation",
    dismiss: "Dismiss",
    download: "Download",
    edit: "Edit",
    editLegalDocument: "Edit legal document",
    eligibilityFiles: "Eligibility files",
    eligibilityList: "Eligibility list",
    expand: "Expand",
    expertJourneysPopoverMenu: "Options for updating the journey",
    eye: "Visible",
    eyeSlash: "Not visible",
    file: "File",
    gatherpalooza: "Gatherpalooza",
    goBack: "Go back",
    goToEvents: "Go to events",
    goToGoal: "Go to goal",
    goToJourney: "Go to journey",
    goToReport: "Go to report",
    goal: "Goal",
    grayceLogo: "Grayce logo",
    handHeart: "Hand with heart",
    headerButtonIcon: "Header button icon",
    help: "Help",
    italic: "Italic",
    link: "Link to item",
    markAsRead: "Mark as read",
    messages: "Messages",
    new: "New",
    next: "Next",
    nextDisabled: "Next disabled",
    open: "Open",
    openMessages: "Open messages",
    orderedList: "Ordered list",
    penWriting: "Pen writing",
    pin: "Pin",
    playButton: "Play button",
    preview: "Preview",
    previous: "Previous",
    print: "Print",
    qbr: "QBR",
    reassign: "Reassign",
    registrations: "Registrations",
    remove: "Remove",
    saveArticle: "Save article",
    search: "Search",
    sendMessage: "Send message",
    share: "Share",
    shareArticle: "Share article",
    sortDown: "Sort down",
    sortUp: "Sort up",
    stickyNote: "Sticky note",
    submit: "Submit",
    to: "To",
    toggle: "Toggle",
    toggleMessages: "Toggle messages",
    toggleTags: "Toggle tags",
    toggleTasks: "Toggle tasks",
    underline: "Underline",
    unpin: "Unpin",
    unreadMessages: "Unread messages",
    useful: "Useful",
    user: "User",
    users: "Users",
    video: "Video",
    warning: "Warning",
    webinar: "Webinar",
    zoom: "Zoom"
  }
};

export default accessibility;
